import { AppRoutes } from './app.routes';

export const DISPLAY_HEADER_DEFAULT_EXCEPTION = [
  AppRoutes.consentPdpa,
  AppRoutes.personalInfo,
  AppRoutes.additionalInfo,
  AppRoutes.customerInsuranceInfo,
  AppRoutes.healthQuestion,
  AppRoutes.revenueTax,
  AppRoutes.consent,
  AppRoutes.fatca,
  AppRoutes.crs,
  AppRoutes.customerAddress,
  AppRoutes.customerBeneficairy,
  AppRoutes.clarification,
  AppRoutes.contact,
  AppRoutes.uploadDocument,
  AppRoutes.loanDetail,
];

export const AUTO_DISPLAY_HEADER_DEFAULT_EXCEPTION = [
  AppRoutes.autoPersonalInfo,
  AppRoutes.autoCustomerAddress,
  AppRoutes.autoCustomerContact,
  AppRoutes.autoLoanInfo,
  AppRoutes.autoCustomerInsuranceInfo,
  AppRoutes.autoCustomerBeneficairy,
  AppRoutes.autoPDPA,
  AppRoutes.autoFatca,
  AppRoutes.autoCrs,
  AppRoutes.autoClarification,
  AppRoutes.autoConsent,
  AppRoutes.autoUploadDocument,
  AppRoutes.autoRevenueTax,
  AppRoutes.autoAdditionalInfo,
  AppRoutes.autoSummary,
];
