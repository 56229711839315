import { useMutation, UseMutationOptions } from 'react-query';
import { url_bff_api } from '../endpoints';
import { APIErrorResponse, ApplicationInput, ApplicationResponse, RequestChannel } from '../types';
import requestApi from 'helpers/requestApi';

export type ApplicationMutationOptions = UseMutationOptions<
  ApplicationResponse,
  APIErrorResponse,
  RequestChannel<ApplicationInput>
>;

export default function useApplicationMutation(options?: ApplicationMutationOptions) {
  return useMutation<ApplicationResponse, APIErrorResponse, RequestChannel<ApplicationInput>>(
    (values) => requestApi.post(values.channel, url_bff_api.application, values.data).then((res: any) => res.data),
    options,
  );
}
