import { memo, useEffect, useState } from 'react';
import { AppButton } from 'components/form-input/button';
import { useTranslation } from 'react-i18next';
import { MenuOutlined, LeftCircleFilled } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useLogOutMutation from 'services/mutations/useLogoutMutation';
import { AppRoutes } from 'helpers/app.routes';
import pkg from '../../../package.json';
import styles from './index.module.scss';
import cls from 'classnames';
import { checkNotAllowUrl } from 'hooks/useInvalidUrlAccess';
import RightOutline from 'assets/images/icons/right-outline.svg';
import ModalMortgageSelect from '../../components/create-app-popup/MortgagePopup';
import { TypeOfLoanEnum } from 'services/types';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCustomerInfoContext } from './customer-info-context';
import { clearAllSessionStorage, clearUserSession } from 'helpers/common';
import { useCurrentUserSetting } from 'hooks/useCurrentUserSetting';

interface Props {
  isLoginProp?: boolean;
  isMenu?: boolean;
  isHaveChild?: boolean;
}
const HeaderComponent: React.FC<Props> = memo(({ isLoginProp, isMenu, isHaveChild, children }) => {
  const { t, i18n } = useTranslation();
  const client = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const {
    actions: { setDataCustomerInfo, setDataApplicationInfo },
  } = useCustomerInfoContext();

  const pathname = history?.location?.pathname;
  const params: any = useParams();
  const getParams = useQueryParams();

  const [isLogin, setLogin] = useState<boolean>(isLoginProp || false);
  const [isDrawer, setDrawer] = useState<boolean>(false);
  const [isShow, setShow] = useState<TypeOfLoanEnum | string>('');
  const isAddMore = getParams.get('isAddMore') || 0;
  const typeOfLoan = getParams.get('typeOfLoan') || '';

  const isMenuDisplay = pathname !== '/';
  const userDataSetting = useCurrentUserSetting();

  useEffect(() => {
    if (typeOfLoan) {
      setShow(typeOfLoan);
    }
  }, [typeOfLoan]);

  let timer1: NodeJS.Timeout | null = null;
  useEffect(() => {
    return () => {
      if (timer1) clearTimeout(timer1);
    };
  }, [timer1]);

  const { mutate: logout, isLoading } = useLogOutMutation({
    onError: (err) => {},
  });

  const handleLogout = () => {
    logout();
    timer1 = setTimeout(() => {
      setDataCustomerInfo(null);
      setDataApplicationInfo(null);
      client.clear();
      localStorage.clear();
      clearUserSession();
      clearAllSessionStorage();
      clearAllSessionStorage();
      setLogin(false);
      history.push(AppRoutes.login);
    }, 300);
  };

  const navigateToApplicationDetail = () => {
    if (location.pathname) {
      const validpath = checkNotAllowUrl(location.pathname);
      const appId = params?.appId;
      if (appId && validpath) history.push(AppRoutes.applicationsParams(appId));
    }
  };

  return (
    <div className="tw-flex md:tw-z-10 md:tw-relative">
      <header
        className={cls(
          styles.tabletHeader,
          'tw-relative tw-bg-white tw-shadow-default tw-flex tw-pl-4 tw-flex-col tw-w-full',
        )}
        style={{
          backgroundColor: 'tranparent !important',
        }}
      >
        <div
          className={cls(styles.tabletHeaderContent, 'tw-flex tw-justify-between tw-items-center tw-h-56px tw-flex-1')}
        >
          <img className={isHaveChild ? cls(styles.FWDImg, styles.haveBorder) : styles.FWDImg} alt="FWD" />
          <div className="tw-flex tw-items-center">
            {isLogin && !isMenuDisplay && (
              <AppButton
                type="primary"
                size="small"
                className="tw-text-13px tw-mr-4 btn-logout-home home-btn-homeLogout"
                onClick={handleLogout}
              >
                {t('label:logOut', 'Logout')}
              </AppButton>
            )}
            <img className={styles.SCBImg} alt="SCB" />
            {isLogin && isMenuDisplay && (
              <div
                className="md:tw-h-98px md:tw-w-90px tw-h-56px tw-w-60px tw-bg-primary tw-flex tw-items-center tw-justify-center tw-text-white"
                onClick={() => setDrawer(!isDrawer)}
              >
                <div className="tw-mx-auto tw-text-center tw-text-white" style={{ lineHeight: 0 }}>
                  <MenuOutlined style={{ fontSize: 18, marginTop: 2, marginBottom: 2 }} />
                  <p className="tw-mb-0 tw-text-sm">Menu</p>
                </div>
              </div>
            )}
            <Drawer
              title={
                <div
                  className="tw-flex tw-items-center tw-justify-start tw-space-x-3"
                  onClick={() => {
                    setDrawer(!isDrawer);
                    history.push(AppRoutes.home);
                  }}
                >
                  <LeftCircleFilled className="tw-text-primary" style={{ fontSize: 20 }} />
                  <span className="tw-font-noto-regular tw-block tw-text-base">
                    {t('label:backToHome', 'Back to Home')}
                  </span>
                </div>
              }
              placement={'right'}
              closable={true}
              onClose={() => setDrawer(!isDrawer)}
              visible={isDrawer}
              key={'right'}
              headerStyle={{ padding: '24px 16px' }}
              bodyStyle={{ padding: '24px 16px' }}
              footer={
                <div className="tw-text-13px tw-font-scb-regular" style={{ color: 'rgba(51, 51, 51, 0.4)' }}>
                  {(process.env.REACT_APP_ENDPOINT === 'SIT' || process.env.REACT_APP_ENDPOINT === 'LOCAL') && (
                    <div
                      onClick={() => {
                        history.push(AppRoutes.sitMenu);
                      }}
                      style={{ marginBottom: '32px' }}
                    >
                      SIT MENU
                    </div>
                  )}
                  <div onClick={navigateToApplicationDetail}>FWD CL Portal version</div>
                  <div>{pkg?.['version'] || '1.0.0'}</div>
                </div>
              }
              footerStyle={{ borderTop: 0 }}
            >
              <div className="tw-mb-4">
                <div
                  className="tw-flex tw-items-center tw-justify-start tw-space-x-3 tw-pb-6"
                  onClick={() => {
                    setDrawer(!isDrawer);
                    setShow(TypeOfLoanEnum.HomeLoan);
                  }}
                >
                  <img src={RightOutline} alt="RightOutline" />
                  <span className="tw-font-noto-regular tw-block tw-text-base">{t('home:titleOfHomeLoan')}</span>
                </div>
                {userDataSetting?.SSME_LOAN_CREATE && (
                  <div
                    className="tw-flex tw-items-center tw-justify-start tw-space-x-3 tw-pb-6"
                    onClick={() => {
                      setDrawer(!isDrawer);
                      setShow(TypeOfLoanEnum.HomeLoan);
                    }}
                  >
                    <img src={RightOutline} alt="RightOutline" />
                    <span className="tw-font-noto-regular tw-block tw-text-base">{t('home:SSME_LOAN')}</span>
                  </div>
                )}
                {/* <div
                  className="tw-flex tw-items-center tw-justify-start tw-space-x-3 tw-pb-6"
                  onClick={() => {
                    setDrawer(!isDrawer);
                    setShow(TypeOfLoanEnum.AutoLoan);
                  }}
                >
                  <img src={RightOutline} alt="RightOutline" />
                  <span className="tw-font-noto-regular tw-block tw-text-base">{t('home:titleOfCarLoan')}</span>
                </div> */}
                <div
                  className="tw-flex tw-items-center tw-justify-start tw-space-x-3 tw-pb-6"
                  onClick={() => {
                    setDrawer(!isDrawer);
                    history.push(AppRoutes.dashboard);
                  }}
                >
                  <img src={RightOutline} alt="RightOutline" />
                  <span className="tw-font-noto-regular tw-block tw-text-base">{t('home:approvalReport')}</span>
                </div>
              </div>

              <AppButton
                type="primary"
                size="small"
                className="tw-text-13px btn-logout-menu dashboard-btn-menuLogout"
                onClick={handleLogout}
                loading={isLoading}
              >
                {t('label:logOut', 'Logout')}
              </AppButton>

              <div className="banner-promote-wrapper tw-mt-8">
                <img className="mortgage-omne-banner" alt="onme-menu-side" />
              </div>
            </Drawer>
          </div>
        </div>

        {isHaveChild && <div className={cls(styles.childrenWraper, 'tw-mb-4')}>{children}</div>}
      </header>

      <ModalMortgageSelect
        visible={Boolean(isShow)}
        destroyOnClose={true}
        onCancel={() => {
          setShow('');
        }}
        onOk={() => {
          setShow('');
        }}
        closable={false}
        titleProps={
          isShow === TypeOfLoanEnum.AutoLoan
            ? t(`home:titleOfCarLoan`, 'ประกันสินเชื่อรถยนต์')
            : t(`home:titleOfHomeLoan`, 'ประกันสินเชื่อบ้าน')
        }
        typeOfLoan={isShow}
        typeOfBorrower={isAddMore === 'true' ? 2 : 0}
      />
    </div>
  );
});

export default HeaderComponent;
