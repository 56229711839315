import { useMutation, UseMutationOptions } from 'react-query';
import { url_bff_api } from '../endpoints';
import { APIErrorResponse, RequestChannel } from '../types';
import requestApi from 'helpers/requestApi';

export type checkDopaSessionInputType = {
  appId: string;
  isReverification?: boolean;
  testErrorCase?: string;
};

export type DOPAMutationOptions = UseMutationOptions<any, APIErrorResponse, RequestChannel<checkDopaSessionInputType>>;

export default function useCheckDOPASessionMutation(options?: DOPAMutationOptions) {
  return useMutation<any, APIErrorResponse, RequestChannel<checkDopaSessionInputType>>((values) => {
    return ['UAT', 'SIT', 'LOCAL'].includes(process.env.REACT_APP_ENDPOINT || '') && values?.data?.testErrorCase
      ? requestApi
          .patch(
            values.channel,
            url_bff_api.checkDOPASession(values?.data.appId),
            { ...values?.data },
            {
              'x-config-dopa': values?.data?.testErrorCase,
            },
          )
          .then((res: any) => res.data)
      : requestApi
          .patch(values.channel, url_bff_api.checkDOPASession(values?.data.appId), { ...values?.data })
          .then((res: any) => res.data);
  }, options);
}
