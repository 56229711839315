import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import { ApplicationDetailResponse, CustomerInfoResponse, MasterDataResponse } from 'services/types';

export const useAutoCustomerInfoContext = () => useContext(AutoCustomerInfoContext);

export const AutoCustomerInfoContext = createContext<any>({
  state: {
    autoDataCustomerInfo: undefined,
    autoDataApplicationInfo: undefined,
    autoMasterData: undefined,
    isAutoLoadCustomer: undefined,
    isAutoLoadApplication: undefined,
  },
  actions: {
    setAutoDataCustomerInfo: () => {},
    setAutoDataApplicationInfo: () => {},
    setAutoMasterData: () => {},
    setAutoIsLoadCustomer: () => {},
    setAutoIsLoadApplication: () => {},
  },
});

export function AutoCustomerInfoContextProvider({ children }: any) {
  const [autoDataCustomerInfo, setAutoDataCustomerInfo] = useState<CustomerInfoResponse | undefined>(undefined);
  const [autoDataApplicationInfo, setAutoDataApplicationInfo] = useState<ApplicationDetailResponse | undefined>(
    undefined,
  );
  const [autoMasterData, setAutoMasterData] = useState<MasterDataResponse | null>(null);
  const [isAutoLoadCustomer, setAutoIsLoadCustomer] = useState<boolean | null>(null);
  const [isAutoLoadApplication, setAutoIsLoadApplication] = useState<boolean | null>(null);

  const value = {
    state: { autoDataCustomerInfo, autoDataApplicationInfo, autoMasterData, isAutoLoadCustomer, isAutoLoadApplication },
    actions: {
      setAutoDataCustomerInfo,
      setAutoDataApplicationInfo,
      setAutoMasterData,
      setAutoIsLoadCustomer,
      setAutoIsLoadApplication,
    },
  };
  return <AutoCustomerInfoContext.Provider value={value}>{children}</AutoCustomerInfoContext.Provider>;
}
