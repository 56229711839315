import { lazy } from 'react';

const lazyRetry = function (componentImport: () => Promise<any>, name: any) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const LazyLayout = (importStatement: () => Promise<any>) => {
  const Component = lazy(importStatement);
  return <Component />;
};

export default {
  Page500: lazy(() => import('./../components/error-status/500')),
  Page404: lazy(() => import('../components/error-status/404')),
  PageAutoNotFound: lazy(() => import('../components/auto-error-timeout')),

  Login: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/login/login/index'), 'Login')),
  LoginOTP: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/login/login-otp/index'), 'LoginOTP')),
  Home: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/home/index'), 'Home')),
  Dashboard: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/dashboard'), 'Dashboard')),
  DashboardSearch: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/dashboard-search'), 'DashboardSearch')),
  SitMenu: () => LazyLayout(() => lazyRetry(() => import('./sit-menu'), 'SitMenu')),
  CustomerSummary: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/cl-calculator/calculator-summary'), 'CustomerSummary')),
  Applications: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/application'), 'Applications')),
  ApplicationsSearch: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./mortgage-pages/application-search/applications-search'), 'ApplicationsSearch'),
    ),
  PersonalInformation: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./mortgage-pages/personal-information/personal-info'), 'PersonalInformation'),
    ),
  CustomerAddress: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/customer-address'), 'CustomerAddress')),
  CustomerInsuranceInfo: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/insurance-info'), 'CustomerInsuranceInfo')),
  CustomerBeneficairy: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/beneficairy-detail'), 'CustomerBeneficairy')),
  HealthQuestion: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/health-question/health-question'), 'HealthQuestion')),

  RevenueTax: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/insurance-info/revenue-tax/revenue-tax'), 'RevenueTax')),
  Clarification: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./mortgage-pages/insurance-info/clarification/clarification'), 'Clarification'),
    ),
  Consent: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/insurance-info/consent/consent'), 'Consent')),
  Contact: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/customer-contact'), 'Contact')),
  AdditionalInfo: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/additional-info'), 'AdditionalInfo')),
  ConsentLanding: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./mortgage-pages/insurance-info/consent-landing/index'), 'ConsentLanding'),
    ),
  LoanDetail: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/loan-detail/loan-detail'), 'LoanDetail')),
  Summary: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/summary'), 'Summary')),
  thankScreen: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/thank/thank-screen'), 'thankScreen')),
  confirmOTP: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/confirm-otp/confirm-otp'), 'confirmOTP')),
  thankClient: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/thank-client/thank-client'), 'thankClient')),
  consentPdpa: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/consent-pdpa'), 'consentPdpa')),
  consentInfor: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/consent-infor'), 'consentInfor')),
  customerVerify: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer-verify/customer-verify'), 'customerDocuments')),
  customerDocuments: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./mortgage-pages/customer-document/customer-documents'), 'customerDocuments'),
    ),
  customerRequestDocuments: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./mortgage-pages/customer-document-verify/index'), 'customerRequestDocuments'),
    ),
  fatca: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/insurance-info/fatca/fatca'), 'fatca')),
  crs: () => LazyLayout(() => lazyRetry(() => import('./mortgage-pages/insurance-info/crs/crs'), 'crs')),
  healthCheckReady: () => LazyLayout(() => lazyRetry(() => import('./health-check/health-ready'), 'healthCheckReady')),
  healthCheckAlive: () => LazyLayout(() => lazyRetry(() => import('./health-check/health-alive'), 'healthCheckAlive')),
  uploadDocument: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/customer/upload-document'), 'uploadDocument')),
  ClCalculator: () =>
    LazyLayout(() => lazyRetry(() => import('./mortgage-pages/cl-calculator/cl-calculator'), 'ClCalculator')),
  Permissions: () => LazyLayout(() => lazyRetry(() => import('./permission/permission'), 'Permissions')),

  AutoPersonalInformation: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/customer/personal-info'), 'AutoPersonalInformation')),
  AutoCustomerBeneficairy: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/customer/beneficairy-detail'), 'AutoCustomerBeneficairy')),
  AutoCustomerAddress: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/customer/customer-address'), 'AutoCustomerAddress')),
  AutoCustomerContact: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/customer/customer-contact'), 'AutoCustomerContact')),
  AutoPDPA: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/customer/consent-pdpa'), 'AutoPDPA')),
  AutoClarification: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/insurance-info/clarification'), 'AutoClarification')),
  AutoConsent: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/insurance-info/consent'), 'AutoConsent')),
  AutoCrs: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/insurance-info/crs'), 'AutoCrs')),
  AutoFatca: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/insurance-info/fatca'), 'AutoFatca')),
  AutoCustomerInsuranceInfo: () =>
    LazyLayout(() =>
      lazyRetry(() => import('./auto-pages/insurance-info/insurance-info'), 'AutoCustomerInsuranceInfo'),
    ),
  AutoRevenueTax: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/insurance-info/revenue-tax'), 'AutoRevenueTax')),
  AutoLoanInfo: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/loan-detail/loan-detail'), 'AutoLoanInfo')),
  AutoUploadDocument: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/upload-document/upload-document'), 'AutoUploadDocument')),
  AutoSummary: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/summary/summary'), 'AutoSummary')),
  AutoAdditionalInfo: () =>
    LazyLayout(() => lazyRetry(() => import('./auto-pages/customer/additional-info'), 'AutoAdditionalInfo')),
  AutoLazyLoad: () => LazyLayout(() => lazyRetry(() => import('../components/auto-popup'), 'AutoLazyLoad')),
  AutoLanding: () => LazyLayout(() => lazyRetry(() => import('./auto-pages/landing/landing'), 'AutoLanding')),
};
