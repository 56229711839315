import { url_bff_api } from 'services/endpoints';

export const DEFAULT_REQUEST_END_POINTS = [
  url_bff_api.uploadImages,
  url_bff_api.censorImage,
  url_bff_api.uploadImage,
  url_bff_api.insurePremium,
  url_bff_api.createApplication,
  url_bff_api.setUserSetting,
];
