import flatten from 'flat';
import label from './label.json';
import button from './button.json';
import home from './home.json';
import dashboard from './dashboard.json';
import errorMessage from './error.json';
import forgotPassErrorMessage from './forgot-password-err.json';
import customer from './customer.json';
import healthQuestion from './health-question.json';
import revenueTax from './revenue-tax.json';
import fatca from './fatca.json';
import clarification from './clarification.json';
import consent from './consent.json';
import summary from './summary.json';
import crs from './crs.json';
import auto from './auto.json';
import common from './common.json';

export default {
  button: flatten<Record<string, any>, typeof label>(button, {
    delimiter: '_',
  }),
  home: flatten<Record<string, any>, typeof label>(home, {
    delimiter: '_',
  }),
  label: flatten<Record<string, any>, typeof label>(label, {
    delimiter: '_',
  }),
  dashboard: flatten<Record<string, any>, typeof label>(dashboard, {
    delimiter: '_',
  }),
  errorMessage: flatten<Record<string, any>, typeof label>(errorMessage, {
    delimiter: '_',
  }),
  forgotPassErrorMessage: flatten<Record<string, any>, typeof label>(forgotPassErrorMessage, {
    delimiter: '_',
  }),
  customer: flatten<Record<string, any>, typeof label>(customer, {
    delimiter: '_',
  }),
  healthQuestion: flatten<Record<string, any>, typeof label>(healthQuestion, {
    delimiter: '_',
  }),
  revenueTax: flatten<Record<string, any>, typeof label>(revenueTax, {
    delimiter: '_',
  }),
  fatca: flatten<Record<string, any>, typeof label>(fatca, {
    delimiter: '_',
  }),
  crs: flatten<Record<string, any>, typeof label>(crs, {
    delimiter: '_',
  }),
  clarification: flatten<Record<string, any>, typeof label>(clarification, {
    delimiter: '_',
  }),
  consent: flatten<Record<string, any>, typeof label>(consent, {
    delimiter: '_',
  }),
  summary: flatten<Record<string, any>, typeof label>(summary, {
    delimiter: '_',
  }),
  auto: flatten<Record<string, any>, typeof label>(auto, {
    delimiter: '_',
  }),
  common: flatten<Record<string, any>, typeof label>(common, {
    delimiter: '_',
  }),
};
