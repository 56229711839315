import axios, { AxiosRequestConfig, Method } from 'axios';
import qs from 'qs';
import { RequestChannelType } from 'services/types';
import { getUserSession } from './common';
import { DEFAULT_REQUEST_END_POINTS } from './defaultRequestEndpoint';

const checkIsDefaultRequest = (config: any) => {
  if (!config) return true;

  for (let index = 0; index < DEFAULT_REQUEST_END_POINTS.length; index++)
    if (config.url === DEFAULT_REQUEST_END_POINTS[index]) return true;

  return false;
};

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = (document.cookie as string).split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';
const METHOD_PATCH = 'PATCH';

async function requestAPI(
  channel: RequestChannelType | null | undefined,
  method: Method,
  url: string,
  headers = {},
  dataBody: any,
  responseType?: ResponseType,
) {
  const config: AxiosRequestConfig = {
    baseURL: !url.startsWith('http')
      ? channel === 'partner'
        ? process.env.REACT_APP_CONNECT_ENV === 'local' && !url.startsWith('/api/partner')
          ? process.env.REACT_APP_API_URL
          : process.env.REACT_APP_API_PARTNER_URL
        : process.env.REACT_APP_API_URL
      : '',
    url: url,
    timeout: 30 * 60 * 1000,
    withCredentials: true,
    headers: headers,
    method: method,
  };

  if (method === METHOD_GET) {
    config.params = dataBody.params;
  } else {
    if (checkIsDefaultRequest(config)) {
      config.data = dataBody;
    } else {
      config.data = qs.stringify(dataBody);
    }
  }

  axios.interceptors.request.use(
    (config: any) => {
      const sessionId = getUserSession();
      Object.assign(config.headers, { apikey: (window as any)._env_?.REACT_APP_RAHADPAN_API });
      if (sessionId && !config.headers['session-id']) {
        Object.assign(config.headers, { 'session-id': sessionId });
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  return axios(config);
}

export default {
  get(
    channel: RequestChannelType | null | undefined,
    url: string,
    dataBody = {},
    headers = {},
    responseType = 'default' as ResponseType,
  ) {
    return requestAPI(channel, METHOD_GET, url, headers, dataBody, responseType);
  },

  post(
    channel: RequestChannelType | null | undefined,
    url: string,
    dataBody: any,
    headers = {},
    responseType = 'default' as ResponseType,
  ) {
    return requestAPI(channel, METHOD_POST, url, headers, dataBody, responseType);
  },
  patch(channel: RequestChannelType | null | undefined, url: string, dataBody: any, headers = {}) {
    return requestAPI(channel, METHOD_PATCH, url, headers, dataBody);
  },

  put(channel: RequestChannelType | null | undefined, url: string, dataBody: any, headers = {}) {
    return requestAPI(channel, METHOD_PUT, url, headers, dataBody);
  },

  delete(channel: RequestChannelType | null | undefined, url: string, dataBody = {}, headers = {}) {
    return requestAPI(channel, METHOD_DELETE, url, headers, dataBody);
  },
};
