import { ComponentType, FC, Fragment } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { Helmet } from 'react-helmet';
import useAuthenticated from 'hooks/useAuthenticated';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { indexOf } from 'lodash';
import { CheckInvalidUrlAccessComponent } from 'components/check-access-component/index';

export type PrivateRouteProps = {
  layout?: ComponentType<any>;
  isPrivate?: boolean;
  isAuthRoute?: boolean;
  title?: string;
  roles?: string[];
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  preventRedirect?: boolean;
} & RouteProps;

const RouteLayout: FC<PrivateRouteProps> = ({
  layout: Layout,
  component: Component,
  isPrivate = false,
  title,
  path,
  isAuthRoute = false,
  roles,
  preventRedirect,
  ...props
}) => {
  const { pathname } = useLocation();
  const queryParam = useQueryParams();
  const redirect = queryParam.get('redirect');
  const isAuthenticated = useAuthenticated();
  const currentUser = useCurrentUser();

  if (!isAuthenticated && isPrivate) {
    return <Redirect key={path as string} push exact={true} to={AppRoutes.login + '?redirect=' + pathname} />;
  }
  if (isAuthenticated && isAuthRoute) {
    {
      return <Redirect push key={path?.toString()} exact={true} to={redirect || AppRoutes.home} />;
    }
  }

  if (isAuthenticated && roles && indexOf(roles, currentUser?.role) < 0) {
    {
      return <Redirect push key={path?.toString()} exact={true} to={AppRoutes.page404} />;
    }
  }
  const getDefaultSrc = () => {
    const environment: any = process.env.REACT_APP_TARGET_ENV;
    if (['local', 'development'].includes(environment)) {
      return `default-src 'self' ${process.env.REACT_APP_API_URL} ${process.env.REACT_APP_API_PARTNER_URL} 'unsafe-inline'; frame-src 'none'; img-src 'self' data:;`;
    }

    return `default-src 'self' https://*.fwd.co.th 'unsafe-inline'; frame-src 'none'; img-src 'self' data:;`;
  };

  const RenderComponent = (ownProps: any) => {
    if (preventRedirect)
      return (
        <CheckInvalidUrlAccessComponent>
          <Component {...ownProps} />
        </CheckInvalidUrlAccessComponent>
      );

    return <Component {...ownProps} />;
  };

  return (
    <Route
      {...props}
      path={path}
      render={(ownProps) => {
        return (
          <Fragment>
            <Helmet>
              <meta httpEquiv="Content-Security-Policy" content={getDefaultSrc()} />
              <title>{title ? title : ''}</title>
            </Helmet>
            {Layout ? <Layout>{RenderComponent(ownProps)}</Layout> : RenderComponent(ownProps)}
          </Fragment>
        );
      }}
    />
  );
};

export default RouteLayout;
