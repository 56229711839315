import { Fragment } from 'react';
import Page from 'pages';
import { AppRoutes, AppTitles } from 'helpers';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from 'components/private-route/index';
import { PrivateRouteProps } from 'components/private-route';
import MainLayout from 'layouts/main';

import ClientLayout from 'layouts/client';
import AutoLayout from 'layouts/auto';

const exact: boolean = true;
const isPrivate: boolean = true;
const isAuthRoute: boolean = true;

export const autoRoutes: PrivateRouteProps[] = [
  {
    path: AppRoutes.partner,
    exact,
    title: AppTitles.partner,
    component: Page.AutoLanding,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.autoPersonalInfo,
    exact,
    title: AppTitles.autoPersonalInfo,
    component: Page.AutoPersonalInformation,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoCustomerBeneficairy,
    exact,
    title: AppTitles.autoCustomerBeneficairy,
    component: Page.AutoCustomerBeneficairy,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoCustomerAddress,
    exact,
    title: AppTitles.autoCustomerAddress,
    component: Page.AutoCustomerAddress,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoCustomerContact,
    exact,
    title: AppTitles.autoCustomerContact,
    component: Page.AutoCustomerContact,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoClarification,
    exact,
    title: AppTitles.autoClarification,
    component: Page.AutoClarification,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoConsent,
    exact,
    title: AppTitles.autoConsent,
    component: Page.AutoConsent,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoCrs,
    exact,
    title: AppTitles.autoCrs,
    component: Page.AutoCrs,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoFatca,
    exact,
    title: AppTitles.autoFatca,
    component: Page.AutoFatca,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoCustomerInsuranceInfo,
    exact,
    title: AppTitles.autoCustomerInsuranceInfo,
    component: Page.AutoCustomerInsuranceInfo,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoRevenueTax,
    exact,
    title: AppTitles.autoRevenueTax,
    component: Page.AutoRevenueTax,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoLoanInfo,
    exact,
    title: AppTitles.autoLoanInfo,
    component: Page.AutoLoanInfo,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoUploadDocument,
    exact,
    title: AppTitles.autoUploadDocument,
    component: Page.AutoUploadDocument,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoPDPA,
    exact,
    title: AppTitles.autoPDPA,
    component: Page.AutoPDPA,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoAdditionalInfo,
    exact,
    title: AppTitles.autoAdditionalInfo,
    component: Page.AutoAdditionalInfo,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoSummary,
    exact,
    title: AppTitles.autoSummary,
    component: Page.AutoSummary,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.autoLazyLoad,
    exact,
    title: AppTitles.autoLazyLoad,
    component: Page.AutoLazyLoad,
    layout: AutoLayout,
    isPrivate,
    preventRedirect: true,
  },
];

export const homeRoutes: PrivateRouteProps[] = [
  ...autoRoutes,
  {
    path: AppRoutes.login,
    exact,
    title: AppTitles.login,
    component: Page.Login,
    isAuthRoute,
    layout: MainLayout,
  },
  {
    path: AppRoutes.loginOTP,
    exact,
    title: AppTitles.loginOTP,
    component: Page.LoginOTP,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.confirmOtp,
    exact,
    title: AppTitles.confirmOTP,
    component: Page.confirmOTP,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.thankScreenClient,
    exact,
    title: AppTitles.thankScreen,
    component: Page.thankClient,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.customerVerify,
    exact,
    title: AppTitles.customerVerify,
    component: Page.customerVerify,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.customerDocuments,
    exact,
    title: AppTitles.customerDocuments,
    component: Page.customerDocuments,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
    preventRedirect: true,
  },
  {
    path: AppRoutes.home,
    exact,
    title: AppTitles.home,
    component: Page.Home,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.dashboard,
    exact,
    title: AppTitles.dashboard,
    component: Page.Dashboard,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.dashboardSearch,
    exact,
    title: AppTitles.dashboardSearch,
    component: Page.DashboardSearch,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.sitMenu,
    exact,
    title: AppTitles.sitMenu,
    component: Page.SitMenu,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.permissions,
    exact,
    title: AppTitles.permissions,
    component: Page.Permissions,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.customerSummary,
    exact,
    title: AppTitles.customerSummary,
    component: Page.CustomerSummary,
    layout: MainLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.applications,
    exact,
    title: AppTitles.applications,
    component: Page.Applications,
    layout: MainLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.applicationsSearch,
    exact,
    title: AppTitles.applicationsSearch,
    component: Page.ApplicationsSearch,
    layout: MainLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.personalInfo,
    exact,
    title: AppTitles.personalInfo,
    component: Page.PersonalInformation,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.customerAddress,
    exact,
    title: AppTitles.customerAddress,
    component: Page.CustomerAddress,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.customerInsuranceInfo,
    exact,
    title: AppTitles.customerInsuranceInfo,
    component: Page.CustomerInsuranceInfo,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.healthQuestion,
    exact,
    title: AppTitles.healthQuestion,
    component: Page.HealthQuestion,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.revenueTax,
    exact,
    title: AppTitles.RevenueTax,
    component: Page.RevenueTax,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.fatca,
    exact,
    title: AppTitles.FATCA,
    component: Page.fatca,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.crs,
    exact,
    title: AppTitles.CRS,
    component: Page.crs,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.clarification,
    exact,
    title: AppTitles.Clarification,
    component: Page.Clarification,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.consent,
    exact,
    title: AppTitles.Consent,
    component: Page.Consent,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.customerBeneficairy,
    exact,
    title: AppTitles.customerBeneficairy,
    component: Page.CustomerBeneficairy,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.contact,
    exact,
    title: AppTitles.contact,
    component: Page.Contact,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.additionalInfo,
    exact,
    title: AppTitles.additionalInfo,
    component: Page.AdditionalInfo,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.consentLanding,
    exact,
    title: AppTitles.consentLanding,
    component: Page.ConsentLanding,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.loanDetail,
    exact,
    title: AppTitles.loanDetail,
    component: Page.LoanDetail,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.summary,
    exact,
    title: AppTitles.summary,
    component: Page.Summary,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.consentPdpa,
    exact,
    title: AppTitles.consentPdpa,
    component: Page.consentPdpa,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.consentInfo,
    exact,
    title: AppTitles.consentPdpa,
    component: Page.consentInfor,
    layout: ClientLayout,
    isPrivate: false,
    preventRedirect: false,
  },
  {
    path: AppRoutes.thankScreen,
    exact,
    title: AppTitles.thankScreen,
    component: Page.thankScreen,
    layout: MainLayout,
    isPrivate,
    preventRedirect: true,
  },
  {
    path: AppRoutes.customerRequestDocuments,
    exact,
    title: AppTitles.customerRequestDocuments,
    component: Page.customerRequestDocuments,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.uploadDocument,
    exact,
    title: AppTitles.uploadDocument,
    component: Page.uploadDocument,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.clCalculation,
    exact,
    title: AppTitles.clCalculation,
    component: Page.ClCalculator,
    layout: MainLayout,
    isPrivate,
    roles: ['SELLER'],
    preventRedirect: true,
  },
  {
    path: AppRoutes.healthCheckReady,
    exact,
    title: AppTitles.healthCheckReady,
    component: Page.healthCheckReady,
    layout: MainLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.healthCheckAlive,
    exact,
    title: AppTitles.healthCheckAlive,
    component: Page.healthCheckAlive,
    layout: MainLayout,
    isPrivate: false,
  },
  { path: AppRoutes.page500, isPrivate: false, component: Page.Page500 },
  { path: AppRoutes.page404, isPrivate: false, component: Page.Page404, layout: ClientLayout },
  { path: AppRoutes.pageAutoNotFound, isPrivate: false, component: Page.PageAutoNotFound, layout: ClientLayout },
];

export const RootRouter = () => {
  // const location = useLocation();
  // const pathName = location.pathname;
  // const isPartner = pathName.split('/').slice(1)[0].toLocaleLowerCase() === 'partner';

  return (
    <Fragment>
      <Switch>
        {homeRoutes.map((route) => (
          <PrivateRoute key={route.path?.toString()} {...route} />
        ))}
        <Redirect from="/" to={AppRoutes.home} />
      </Switch>
    </Fragment>
  );
};
