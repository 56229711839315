import { useCurrentUser } from 'hooks/useCurrentUser';

function useAuthenticated() {
  const user = useCurrentUser();

  if (user && user.isOTPPass == false) return false;

  return !!user;
}

export default useAuthenticated;
