import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import { ApplicationDetailResponse, CustomerInfoResponse, MasterDataResponse } from 'services/types';

export const useCustomerInfoContext = () => useContext(CustomerInfoContext);

export const CustomerInfoContext = createContext<any>({
  state: {
    dataCustomerInfo: undefined,
    dataApplicationInfo: undefined,
    masterData: undefined,
    isLoadCustomer: undefined,
    isLoadApplication: undefined,
  },
  actions: {
    setDataCustomerInfo: () => {},
    setDataApplicationInfo: () => {},
    setMasterData: () => {},
    setIsLoadCustomer: () => {},
    setIsLoadApplication: () => {},
  },
});

export function CustomerInfoContextProvider({ children }: any) {
  const [dataCustomerInfo, setDataCustomerInfo] = useState<CustomerInfoResponse | undefined>(undefined);
  const [dataApplicationInfo, setDataApplicationInfo] = useState<ApplicationDetailResponse | undefined>(undefined);
  const [masterData, setMasterData] = useState<MasterDataResponse | null>(null);
  const [isLoadCustomer, setIsLoadCustomer] = useState<boolean | null>(null);
  const [isLoadApplication, setIsLoadApplication] = useState<boolean | null>(null);

  const value = {
    state: { dataCustomerInfo, dataApplicationInfo, masterData, isLoadCustomer, isLoadApplication },
    actions: { setDataCustomerInfo, setDataApplicationInfo, setMasterData, setIsLoadCustomer, setIsLoadApplication },
  };
  return <CustomerInfoContext.Provider value={value}>{children}</CustomerInfoContext.Provider>;
}
