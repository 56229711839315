import './App.css';
import { RootRouter } from './routes';
import { ProgressLoading } from 'components/progress-loading';
import { createContext, Suspense, useEffect } from 'react';
import { useMeQuery } from './services/queries/useMeQuery';
import Spinner from './components/spinner';
import { NetworkStatusCode, RetrieveMyInformationResponse, ThrowMessageEnum } from './services/types';
import { UserEntity } from 'services/types/user';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { GlobalDebug } from 'helpers/remove-consoles';
import { LocationContextProvider } from 'hooks';
import { Debugger } from 'hooks/locationContextProvider';
import { useQueryUserSetting } from 'services/queries/useGetUserSetting';
import { CustomerInfoContextProvider } from 'layouts/main/customer-info-context';

import { AnnouncementContextProvider } from 'hooks/announcementContextProvider';
import { AutoCustomerInfoContextProvider } from 'layouts/auto/customer-info-context';
import { decryptResponseData, setUserSession } from 'helpers/common';

export const UserContext = createContext<RetrieveMyInformationResponse | undefined>(undefined);
function App() {
  const location = useLocation();
  const pathName = location.pathname;

  decryptResponseData({ data: (window as any)._env_.REACT_APP_DATA })
    .then((decryptedData) => {
      (window as any)._env_ = { ...(window as any)._env_, ...JSON.parse(JSON.parse(decryptedData)) };
      return;
    })
    .catch((error) => {
      console.error('Error decrypting data:', error);
    });

  // /**
  //  * @REMOVE_CONSOLES
  //  * // remove the working of console logs
  //  * // remove any accidental use of console logs
  //  */
  useEffect(() => {
    const environment: any = process.env.REACT_APP_TARGET_ENV;
    (environment === 'production' || environment === 'uat') && GlobalDebug(false, false);
  }, []);

  const { isLoading, data } = useMeQuery({
    onError: (err: any) => {
      if (
        err?.response?.status === NetworkStatusCode.NOT_FOUND &&
        err?.response?.data?.message === ThrowMessageEnum.INVALID_SESSION
      ) {
        if (pathName !== AppRoutes.customerInfo) setUserSession('');
      }
    },
  });

  useQueryUserSetting({
    onError: (err: any) => {
      if (
        err?.response?.status === NetworkStatusCode.NOT_FOUND &&
        err?.response?.data?.message === ThrowMessageEnum.INVALID_SESSION
      ) {
        if (pathName !== AppRoutes.customerInfo) setUserSession('');
      }
    },
  });

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Suspense fallback={<ProgressLoading />}>
      <UserContext.Provider value={data as UserEntity}>
        <LocationContextProvider>
          <CustomerInfoContextProvider>
            <AutoCustomerInfoContextProvider>
              <AnnouncementContextProvider>
                <RootRouter />
                <Debugger />
              </AnnouncementContextProvider>
            </AutoCustomerInfoContextProvider>
          </CustomerInfoContextProvider>
        </LocationContextProvider>
      </UserContext.Provider>
    </Suspense>
  );
}

export default App;
