export { AppRoutes, AppTitles } from './app.routes';
import Resizer from 'react-image-file-resizer';

export const ResizeImage = (file: any, width: any = 1024, height: any = 768) =>
  new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob',
      );
    } catch (ex) {
      reject(ex);
    }
  });

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, '');
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  return formatted;
};
