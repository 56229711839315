import { TypeOfLoanEnum, Channel } from 'services/types';

interface AppTitleProp {
  [key: string]: string;
}
export const AppRoutes = {
  // replaceParams(route: string, params: Record<string, string> = {}) {
  //   let finalRoute = route;
  //   Object.keys(params).forEach((key) => {
  //     finalRoute = finalRoute.replace(`:${key}`, params[key]);
  //   });
  //   return finalRoute;
  // },
  partner: '/partner/:token',
  home: '/home',
  login: '/login',
  loginOTP: '/login-otp',
  logout: '/logout',
  dashboard: '/dashboard',
  dashboardSearch: '/dashboard-search',
  personalInfo: `/personal-info/:appId?`,
  customerInfo: '/customer-info',
  customerSummary: '/customer-summary',
  applicationsSearch: '/applications-search',
  sitMenu: '/sit-menu',
  permissions: '/permissions',
  applications: '/applications/:appId',
  customerAddress: '/address/:appId',
  customerInsuranceInfo: '/insurance-info/:appId',
  customerContact: '/contact/:appId',
  customerBeneficairy: '/beneficairy-detail/:appId',
  healthQuestion: '/health-question/:appId?',
  revenueTax: '/revenue-tax/:appId?',
  fatca: '/fatca/:appId?',
  crs: '/crs/:appId?',
  clarification: '/clarification/:appId?',
  consent: '/consent/:appId?/:appNo?/:consentId?',
  consentLanding: '/consent-landing/:typeOfLoan?',
  contact: '/contact/:appId',
  additionalInfo: '/additional-info/:appId',
  loanDetail: '/loan-detail/:appId',
  summary: '/summary/:appId',
  thankScreen: '/thank/:typeOfLoan?/:otpType?',
  thankScreenClient: '/thank-client',
  confirmOtp: '/cotp/:appId',
  consentPdpa: '/pdpa/:appId',
  consentInfo: '/consent-info/:appId',
  customerVerify: '/cvsi/:appId',
  customerDocuments: '/customer-documents/:appId',
  customerRequestDocuments: '/customer-request-documents/:appId',
  uploadDocument: '/upload-document/:appId',
  clCalculation: '/cl-calculator/:borrowerType',
  autoLazyLoad: '/auto/:appId',
  autoPersonalInfo: '/auto-personal-info/:appId',
  autoCustomerAddress: '/auto-address/:appId',
  autoCustomerContact: '/auto-contact/:appId',
  autoLoanInfo: '/auto-loan-detail/:appId',
  autoCustomerInsuranceInfo: '/auto-insurance-info/:appId',
  autoPDPA: '/auto-pdpa/:appId',
  autoCustomerBeneficairy: '/auto-beneficairy-detail/:appId',
  autoFatca: '/auto-fatca/:appId',
  autoCrs: '/auto-crs/:appId',
  autoClarification: '/auto-clarification/:appId',
  autoConsent: '/auto-consent/:appId?/:appNo?/:consentId?',
  autoUploadDocument: '/auto-upload-document/:appId',
  autoSummary: '/auto-summary/:appId',
  autoRevenueTax: '/auto-revenue-tax/:appId',
  autoAdditionalInfo: '/auto-additional-info/:appId',

  autoLazyLoadParams: (appId: string) => `/auto/${appId}`,
  autoPersonalInfoParams: (appId: string) => `/auto-personal-info/${appId}`,
  autoCustomerAddressParams: (appId: string) => `/auto-address/${appId}`,
  autoCustomerContactParams: (appId: string) => `/auto-contact/${appId}`,
  autoLoanInfoParams: (appId: string) => `/auto-loan-detail/${appId}`,
  autoCustomerInsuranceInfoParams: (appId: string) => `/auto-insurance-info/${appId}`,
  autoPDPAParams: (appId: string) => `/auto-pdpa/${appId}`,
  autoCustomerBeneficairyParams: (appId: string) => `/auto-beneficairy-detail/${appId}`,
  autoFATCAParams: (appId: string) => `/auto-fatca/${appId}`,
  autoCRSParams: (appId: string) => `/auto-crs/${appId}`,
  autoClarificationParams: (appId: string | number, step: string | number = '1') => {
    if (step) return `/auto-clarification/${appId}/?step=${step}`;
    return `/auto-clarification/${appId}`;
  },
  autoConsentParams: (params: any) => {
    let baseURL = `/auto-consent/${params.appId}`;
    if (params.appNo) baseURL = baseURL + `/${params.appNo}`;
    if (params.consentId) baseURL = baseURL + `/${params.consentId}`;
    return baseURL;
  },
  autoUploadDocumentParam: (appId: string) => `/auto-upload-document/${appId}`,
  autoSummaryParams: (appId: string) => `/auto-summary/${appId}`,
  autoRevenueTaxParams: (appId: string) => `/auto-revenue-tax/${appId}`,
  autoAutoAdditionalInfoParam: (appId: string) => `/auto-additional-info/${appId}`,
  clCalculationParams: (params: { borrowerType: string; appId?: string; scbAppNo?: string }) => {
    if (params.scbAppNo) return `/cl-calculator/${params.borrowerType}?scbAppNo=${params.scbAppNo}`;
    return `/cl-calculator/${params.borrowerType}`;
  },
  clCalculationParamsAppId: (params: { borrowerType: string; appId?: string; scbAppNo?: string }) =>
    `/cl-calculator/${params.borrowerType}?appId=${params.appId}`,
  uploadDocumentParam: (params: any) => `/upload-document/${params?.appId}`,
  consentInfoParams: (params: any) => `/consent-info/${params?.appId}`,
  consentPdpaParams: (appId: string) => `/pdpa/${appId}`,
  customerSummaryParams: (params: any) => {
    if (params.scbAppNo && params.scbAppNo != undefined)
      return `/customer-summary?appId=${params.appId}&scbAppNo=${params.scbAppNo}`;
    return `/customer-summary?appId=${params.appId}`;
  },
  summaryParams: (appId: string) => `/summary/${appId}`,
  personalInfoParams: (params: any) => `/personal-info/${params.appId}`,
  applicationsParams: (appId: string | number) => `/applications/${appId}`,
  customerAddressParams: (appId: string | number) => `/address/${appId}`,
  customerInsuranceInfoParams: (appId: string | number) => `/insurance-info/${appId}`,
  customerBeneficairyParams: (id: string | number) => `/beneficairy-detail/${id}`,
  contactParams: (appId: string | number) => `/contact/${appId}`,
  additionalInfoParams: (appId: string | number) => `/additional-info/${appId}`,
  healthQuestionParams: (appId: string | number) => `/health-question/${appId}`,
  revenueTaxParams: (params: any) => `/revenue-tax/${params.appId}`,
  FATCAParams: (appId: string | number) => `/fatca/${appId}`,
  CRSParams: (appId: string | number) => `/crs/${appId}`,
  clarificationParams: (appId: string | number, step: string | number = '1') => {
    if (step) return `/clarification/${appId}?step=${step}`;

    return `/clarification/${appId}`;
  },
  consentParams: (params: any) => {
    let baseURL = `/consent/${params.appId}`;
    if (params.appNo) baseURL = baseURL + `/${params.appNo}`;
    if (params.consentId) baseURL = baseURL + `/${params.consentId}`;
    return baseURL;
  },
  homeParams: (isAddMore: boolean, typeOfLoan: TypeOfLoanEnum, scbNo: string) => {
    let baseURL = `/home?isAddMore=${isAddMore.toString()}&typeOfLoan=${typeOfLoan}`;
    if (scbNo) baseURL = baseURL + `&scbAppNo=${scbNo}`;
    return baseURL;
  },
  consentLandingParams: (typeOfLoan: TypeOfLoanEnum | string) => `/consent-landing/${typeOfLoan}`,
  loanDetailParams: (appId: string | number) => `/loan-detail/${appId}`,
  confirmOtpParams: (appId: string | number) => `/cotp/${appId}`,
  thankScreenParams: (typeOfLoan: TypeOfLoanEnum | string, otpType: Channel | '') => `/thank/${typeOfLoan}/${otpType}`,
  customerVerifyParams: (appId: string | number) => `/cvsi/${appId}`,
  customerDocumentsParams: (appId: string | number) => `/customer-documents/${appId}`,
  customerRequestDocumentsParams: (appId: string | number) => `/customer-request-documents/${appId}`,

  partnerParams: (token: string | number) => `/partner/${token}`,
  healthCheckReady: `/health-check/ready`,
  healthCheckAlive: `/health-check/alive`,
  page500: '/500',
  page404: '/404',
  pageAutoNotFound: '/auto-notfound',
};

export const AppTitles: AppTitleProp = {
  partner: 'Partner',
  home: 'Home',
  login: 'Login',
  dashboard: 'Dashboard',
  dashboardSearch: 'Dashboard search',
  personalInfo: 'Personal information',
  customerInfo: 'Customer information',
  customerSummary: 'Customer summary',
  applications: 'Applications',
  applicationsSearch: 'Applications Search',
  customerAddress: 'Customer address',
  customerInsuranceInfo: 'Insurance information',
  customerBeneficairy: 'Beneficairy Detail',
  contact: 'Contact',
  additionalInfo: 'Additional Info',
  healthQuestion: 'Health question',
  RevenueTax: 'Revenue Tax',
  FATCA: 'FATCA',
  CRS: 'CRS',
  Clarification: 'Clarification',
  Consent: 'Consent',
  ConsentLanding: 'Consent Landing',
  loanDetail: 'Mortgage Loan Detail',
  summary: 'Summary',
  thankScreen: 'Thank',
  confirmOTP: 'Confirm OTP',
  consentPdpa: 'PDPA',
  customerVerify: 'Customer Verify',
  customerDocuments: 'Customer Documents',
  customerRequestDocuments: 'Customer Request Documents',
  healthCheckReady: `Health Ready`,
  healthCheckAlive: `Health Alive`,
  uploadDocument: 'Upload Document',
  sitMenu: 'Sit Menu',
  permissions: 'Permissions',
  clCalculation: 'CL Calculator',
  clCalculationSaleIllust: 'CL Calculator',
  autoPersonalInfo: 'Auto Personal Info',
  autoCustomerAddress: 'Auto Customer Address',
  autoCustomerContact: 'Auto Customer Contact',
  autoLoanInfo: 'Auto Loan Info',
  autoCustomerInsuranceInfo: 'Auto Customer Insurance Info',
  autoCustomerBeneficairy: 'Auto Customer Beneficairy',
  autoPDPA: 'Auto PDPA',
  autoFatca: 'Auto Fatca',
  autoCrs: 'Auto Crs',
  autoClarification: 'Auto Clarification',
  autoConsent: 'Auto Consent',
  autoSummary: 'Auto Summary',
  autoUploadDocument: 'Auto Upload Document',
  autoRevenueTax: 'Auto Revenue Tax',
  autoAdditionalInfo: 'Auto Additional Info',
  autoLazyLoad: 'Auto Loading',
};
