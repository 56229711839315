import { Layout } from 'antd';
import { memo, ReactNode, useEffect, useState, useMemo } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import ConfirmModal from 'components/popup/ConfirmPop';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath, useParams } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { useQueryClient } from 'react-query';
import { addEventListeners, removeEventListeners } from '../../helpers/eventListenerUtil';
import { useExtendSession } from 'services/queries/useExtendSession';
import { UseHookError } from 'hooks/useHookError';
import { AUTO_DISPLAY_HEADER_DEFAULT_EXCEPTION } from 'helpers/displayHeaderDefaultException';
import styles from './index.module.scss';
import cls from 'classnames';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import HeaderComponent from './header';
import { AutoBreadcrumbComponent } from 'components/auto-breadcrumb';
import { AutoSideMenuComponent } from 'components//auto-side-menu';
import { MasterDataResponse, TypeGender, RequestChannelEnum } from 'services/types';
import { useCustomerInfoLazyQuery } from 'services/queries/useCustomerInfoQuery';
import { useApplicationInfoLazyQuery } from 'services/queries/useApplicationQuery';
import { mappingAutoStepNumber } from 'helpers/constants';
import React from 'react';
import { useAutoCustomerInfoContext } from './customer-info-context';
import { useMasterDataLazyQuery } from 'services/queries/useMasterDataQuery';
import { clearAllSessionStorage, clearUserSession, getEnumKeyByEnumValue, getUserSession } from 'helpers/common';
import { useAnnouncementContext } from 'hooks/announcementContextProvider';
import { useWindowSize, DebouncedWindowSizeOptions } from '@react-hook/window-size';

const { Header, Content, Sider } = Layout;

type Props = {
  children: ReactNode;
  isMenu?: boolean;
};

const AutoLayout = memo<Props>(({ children }) => {
  const [isVisibleNearTimeout, setIsVisibleNearTimeout] = useState<boolean>(false);
  const [isPageNotFound, setIsPageAutoNotFound] = useState<boolean>(false);
  const user = useCurrentUser();
  const optionsWindow: DebouncedWindowSizeOptions = {
    initialWidth: 360,
  };
  const [onlyWidth, onlyHeight] = useWindowSize(optionsWindow);

  const { t } = useTranslation();
  const history = useHistory();
  const client = useQueryClient();
  const [onErrors, setOnErrors] = useState<any>({});
  const [indexOfPopup, setIndexOfPopup] = useState<number>(0);
  const masterDataQuery = useMasterDataLazyQuery();

  const pathname = history?.location?.pathname;

  const sessionId = getUserSession();
  const [collapsed, setCollapsed] = useState(true);
  const params: any = useParams();
  const {
    state: { autoDataCustomerInfo, autoDataApplicationInfo, autoMasterData },
    actions: {
      setAutoDataCustomerInfo,
      setAutoDataApplicationInfo,
      setAutoMasterData,
      setAutoIsLoadCustomer,
      setAutoIsLoadApplication,
    },
  } = useAutoCustomerInfoContext();

  const { announcement, setAnnouncement } = useAnnouncementContext();
  const announcementData = JSON.parse(announcement || '[]');

  useEffect(() => {
    if (!sessionId) return;
    const createTimeout1 = () => {
      console.log('createNearTimeout start');
      return setTimeout(() => {
        setIsVisibleNearTimeout(true);
      }, 1000 * 60 * 13);
    };

    const createTimeout2 = () => {
      console.log('createTimeout start');
      return setTimeout(() => {}, 100);
    };

    const listener = () => {
      if (!isVisibleNearTimeout) {
        console.log('clear');
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout = isVisibleNearTimeout ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      console.log('clear');
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isVisibleNearTimeout, sessionId]);

  const { mutate: getCustomerInfo, isLoading: isCustomerLoading } = useCustomerInfoLazyQuery({
    onSuccess: (res) => {
      setAutoDataCustomerInfo(res);
      setOnErrors({});
    },
    onError: (err) => {
      setOnErrors(err);
    },
  });
  const { mutate: getApplicationsInfo, isLoading: isApplicationLoading } = useApplicationInfoLazyQuery({
    onSuccess: (res) => {
      setAutoDataApplicationInfo(res);
      setOnErrors({});
    },
    onError: (err: any) => {
      setOnErrors(err);
    },
  });
  const handleGetMasterData = async () => {
    const _resp: MasterDataResponse = await masterDataQuery.mutateAsync(
      {
        data: { gender: getEnumKeyByEnumValue(TypeGender, autoDataCustomerInfo?.gender || TypeGender.MALE) },
        channel: RequestChannelEnum.PARTNER,
      },
      {
        onError: (error: any) => {
          setOnErrors(error || {});
        },
      },
    );
    setAutoMasterData(_resp);
  };

  useEffect(() => {
    setAutoIsLoadCustomer(isCustomerLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerLoading]);

  useEffect(() => {
    setAutoIsLoadApplication(isApplicationLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplicationLoading]);

  useEffect(() => {
    setAutoIsLoadCustomer(null);
    setAutoIsLoadApplication(null);
    if (params && params.appId && location.pathname) {
      const stepItem = mappingAutoStepNumber.find((item) => location.pathname.includes(item.path));
      if ((!autoDataApplicationInfo || stepItem?.isloadApplicationInfo) && !isCustomerLoading) {
        getApplicationsInfo({ data: { id: params.appId }, channel: RequestChannelEnum.PARTNER });
      }

      if ((!autoDataCustomerInfo || stepItem?.isLoadCustomerInfo) && !isApplicationLoading) {
        getCustomerInfo({
          data: { ...params, reCalculatePremium: stepItem?.reCalculatePremium || false },
          channel: RequestChannelEnum.PARTNER,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (autoDataCustomerInfo && !autoMasterData) {
      handleGetMasterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDataCustomerInfo, autoMasterData]);

  useEffect(() => {
    if (pathname) {
      getAnnouncementBypage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (isPageNotFound) {
      client.clear();
      localStorage.clear();
      sessionStorage.removeItem('sessionId');
      window.sessionStorage.setItem('latestLocation', '');
      history.push(AppRoutes.pageAutoNotFound);
    }
  }, [isPageNotFound, client, history]);

  const getAnnouncementBypage = () => {
    const dataByPage = announcementData
      ?.filter((el: any) => pathname.includes(el.location) && el.displayFrequency > 0)
      ?.sort((a: any, b: any) => a.index - b.index);
  };

  const announcementDataByPage = useMemo(() => {
    const dataByPage = announcementData
      ?.filter((el: any) => pathname.includes(el.location) && el.displayFrequency > 0)
      ?.sort((a: any, b: any) => a.index - b.index);
    return dataByPage;
  }, [pathname, announcementData]);

  const { refetch } = useExtendSession(
    { data: {}, channel: RequestChannelEnum.PARTNER },
    {
      onSuccess: (res) => {
        setOnErrors({});
      },
      onError: (err) => {
        setOnErrors(err || {});
      },
      cacheTime: 0,
      enabled: false,
    },
  );

  const checkRenderHeaderDefault = () => {
    let isRender = true;

    for (const route of AUTO_DISPLAY_HEADER_DEFAULT_EXCEPTION) {
      const isMatch = matchPath(pathname, route);
      if (isMatch) {
        isRender = false;
        break;
      }
    }
    return isRender;
  };

  const rendererTimer = ({ total, completed }: CountdownRenderProps) => {
    if (completed) {
      return <span>0</span>;
    } else {
      return <span>{total / 1000}</span>;
    }
  };

  const onClearAnnouncement = (id: string) => {
    let indexOfCurrentPopup = indexOfPopup;
    const updatedAnnouncementData = [...announcementData];
    updatedAnnouncementData.forEach((element: any) => {
      if (element.id === id) {
        element.displayFrequency -= 1;
        return;
      }
    });

    setAnnouncement(JSON.stringify(updatedAnnouncementData));
    localStorage.setItem('announcement-data', JSON.stringify(updatedAnnouncementData || '[]'));
    setIndexOfPopup((indexOfCurrentPopup += 1));
  };

  const getBgColorHeader = () => {
    if (history.location.pathname == '/login') return '#170102';
    if (!checkRenderHeaderDefault()) return 'white';

    return '#f5f3f6';
  };

  const onCancelModal = () => {
    // setIsVisibleNearTimeout(false);
    client.clear();
    localStorage.clear();
    clearUserSession();
    clearAllSessionStorage();
    history.push(AppRoutes.pageAutoNotFound);
  };

  const checkRenderScroll = () => {
    return onlyWidth ? (onlyWidth < 768 ? false : true) : true;
  };

  const _cls = history.location.pathname == '/login' ? styles.tabletLogin : '';
  const _classPadding = 'tw-pt-0 tw-pl-0 tw-pr-0 md:tw-pt-10 md:tw-pl-6 md:tw-pr-10';
  const _classDefault = 'tw-mt-0 tw-ml-0 tw-mr-0';
  const _tabletScrollViewPort = onlyHeight ? `calc(${onlyHeight}px - 98px)` : 'calc(100vh - 98px)';

  return (
    <>
      <Layout className="tw-bg-input tw-bg-white">
        <Header
          className={cls('md:tw-sticky', { 'tw-sticky': checkRenderHeaderDefault() == true }, styles.headerMobile)}
          style={{
            top: 0,
            zIndex: 1,
            padding: 0,
            height: 'inherit',
            backgroundColor: getBgColorHeader(),
          }}
        >
          <HeaderComponent
            isLoginProp={Boolean(user?.isOTPPass)}
            isMenu={false}
            isHaveChild={checkRenderHeaderDefault() != true}
          >
            <AutoBreadcrumbComponent></AutoBreadcrumbComponent>
          </HeaderComponent>
        </Header>

        <Layout className={cls(_cls)}>
          {checkRenderHeaderDefault() != true && (
            <Sider
              className={styles.mainSider}
              trigger={null}
              width={242}
              breakpoint="md"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                setCollapsed(broken);
              }}
              onCollapse={(collapsed, type) => {
                setCollapsed(collapsed);
              }}
              style={{
                overflowY: 'auto',
                height: _tabletScrollViewPort,
                position: 'sticky',
                top: 98,
                left: 0,
              }}
            >
              <AutoSideMenuComponent></AutoSideMenuComponent>
            </Sider>
          )}

          <Layout
            style={{
              ...(checkRenderScroll() && { overflowY: 'auto' }),
              height: checkRenderScroll() ? _tabletScrollViewPort : 'inherit',
            }}
          >
            <Content
              style={{
                minHeight: 'calc(100vh - 98px)',
              }}
              className={checkRenderHeaderDefault() ? _classDefault : _classPadding}
            >
              {!checkRenderHeaderDefault() && (
                <div className={cls(styles.loanContent)}>
                  <div className={cls(styles.subLoanContent, 'tw-text-primary tw-font-scb-medium')}>
                    {t('auto:scbLoanNumber')} : {autoDataCustomerInfo?.scbApplicationNo}
                  </div>

                  <div className={cls(styles.subLoanContent, 'tw-text-primary tw-font-scb-medium')}>
                    {t('auto:insuranceNo')} : {autoDataApplicationInfo?.applicationNo}
                  </div>
                </div>
              )}
              {children}
            </Content>
          </Layout>
          <ConfirmModal
            closable={false}
            showButton={true}
            manualClosable={false}
            visible={isVisibleNearTimeout}
            destroyOnClose={true}
            title={t('home:session_near_timeout')}
            okTextProp={t('button:confirm_btn')}
            message={
              <div style={{ fontSize: '13px', lineHeight: '20px' }}>
                {t('home:session_near_timeout_a')} <br />
                {t('home:session_near_timeout_b')}{' '}
                <Countdown
                  date={Date.now() + 120000}
                  intervalDelay={0}
                  precision={0}
                  renderer={rendererTimer}
                  onComplete={() => {
                    console.log('time out');
                    client.clear();
                    localStorage.clear();
                    clearUserSession();
                    clearAllSessionStorage();
                    setIsVisibleNearTimeout(false);
                    setIsPageAutoNotFound(true);
                  }}
                />{' '}
                {t('home:second_suffix')}
              </div>
            }
            onOk={() => {
              setIsVisibleNearTimeout(false);
              // refetch();
              // setIsPageAutoNotFound(true);
            }}
          />
          {/* <ConfirmModal
            closable={false}
            showButton={true}
            visible={isVisibleTimeout}
            manualClosable={false}
            destroyOnClose={true}
            title={t('home:err_session_title')}
            message={
              <div style={{ fontSize: '13px', lineHeight: '20px' }}>
                {t('home:err_session_new_a')} <br />
                {t('home:err_session_new_b')}
              </div>
            }
            onOk={() => {
              setIsVisibleTimeout(false);
              client.clear();
              localStorage.clear();
              clearUserSession();
              clearAllSessionStorage();
              onCancelModal();
            }}
          /> */}

          {/* {announcementDataByPage.length ? (
            <>
              {announcementDataByPage.map((el: any, index: number) => {
                return (
                  <div key={el.id}>
                    <ConfirmModal
                      width={'none'}
                      className={styles.announcementDialog}
                      closable={false}
                      showButton={true}
                      maskClosable={true}
                      visible={index === indexOfPopup}
                      title={el.title}
                      message={<span dangerouslySetInnerHTML={{ __html: el.description }}></span>}
                      onOk={() => {
                        onClearAnnouncement(el.id);
                      }}
                      onCancel={() => {
                        onClearAnnouncement(el.id);
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            ''
          )} */}
          <UseHookError err={onErrors} />
        </Layout>
      </Layout>
    </>
  );
});

export default AutoLayout;
