export const url_bff_api = {
  login: '/api/accounts/login',
  logout: '/api/accounts/logout',
  resetPassword: '/api/accounts/reset',
  unlockAccount: '/api/accounts/unlock',
  retrieveMyInformation: '/api/agents',
  application: '/api/application',
  applicationPost: (appId: string) => `/api/application/post/${appId}`,
  occupations: '/api/common/occupations',
  nationals: '/api/common/nationals',
  maritalStatus: '/api/common/marital-status',
  nationality: '/api/common/nationals',
  getCommonAgent: (id: string) => `/api/common/agent/${id}`,
  customerProfile: (params: any) => {
    return !params?.reCalculatePremium
      ? `/api/application/customer-profile/${params?.appId}`
      : `/api/application/customer-profile/${params?.appId}?reCalculatePremium=${params?.reCalculatePremium}`;
  },
  customerSummary: (appId: string) => `/api/application/customer-profile/summary/${appId}`,
  applicationDetail: (appId: string) => `/api/application/${appId}`,
  insureProfile: (id: string) => `/api/insure/${id}`,
  BeneficairyDetail: (id: string) => `/api/beneficiary/${id}`,
  saveBeneficairy: `/api/beneficiary/save`,
  titles: (gender: string) => `/api/common/titles?gender=${gender}`,
  updateInsureProfile: '/api/insure/save',
  applicationList: '/api/application/list',
  getHealthQuestionInfo: (appId: string) => `api/health-question/${appId}`,
  saveHealthQuestionInfo: `api/health-question/save`,
  updateInsuranceInfo: (appId: string) => `/api/application/insurance-summary/${appId}`,
  deleteApplication: (appId: string) => `/api/application/delete/${appId}`,
  contact: (appId: string) => `/api/contact/${appId}`,
  getAdditional: (appId: string) => `/api/insure/additional/${appId}`,
  province: `/api/common/provinces`,
  addressSave: `/api/contact/save-address`,
  updateRevenueInfo: '/api/insure/revenue-department',
  getFATCAInfo: (appId: string) => `api/fatca/${appId}`,
  saveFATCAInfo: `api/fatca/save`,
  geCRSInfo: (appId: string) => `api/fatca/crs/${appId}`,
  saveCRSInfo: `api/fatca/crs`,
  saveConsentInfo: `api/consent/save`,
  contactSave: `/api/contact/save-phone`,
  additionalInfo: `/api/insure/additional`,
  getDocument: (id: string) => `/api/document/v2/${id}`,
  saveClarification: `api/fatca/confirm-clarification`,
  getRelationships: '/api/common/relationships',
  getAnnouncement: '/api/announcement',
  sendNotice: '/api/quotation/sales-illustration/v2',
  loanDetail: '/api/loan-info/save',
  getLoanDetail: (id: string) => `api/loan-info/${id}`,
  getPhone: (id: string) => `api/verification/phone-number/${id}`,
  resendOtp: '/api/verification/resend-otp',
  resendOtpV2: '/api/verification/resend-otp-v2',
  sendOtp: '/api/verification/send-otp/v2',
  confirmOtp: '/api/verification/confirm-otp/v2',
  getDocumentList: (appId: string) => `/api/document/list/${appId}`,
  consentTemplate: `/api/consent/consent-template`,
  savePdpa: '/api/consent/save-pdpa',
  consentPdpa: (appId: string) => `/api/consent/pdpa/${appId}`,
  documentCredential: (params: any) =>
    `/api/document/credential/v2?applicationId=${params.applicationId}&password=${params.password}`,
  getDocumentCustomerRequestList: (appId: string) => `/api/document/list-file/${appId}`,
  getDocumentCustomerRequest: (id: string) => `/api/document/detail/${id}`,
  checkLink: (params: any) => `/api/communication/validate-link?applicationId=${params?.appId}&type=${params?.type}`,
  checkHealthReady: '/api/health/ready',
  checkHealthLive: '/api/health/alive',
  uploadImage: '/api/insure/upload-document',
  uploadImages: '/api/insure/upload-documents/v2',
  censorImage: '/api/document/image-censor',
  insurePremium: '/api/premium/retrieve/insure-premium',
  getDocumentByApp: (appId: string) => `/api/document/list-docid-by-app/${appId}`,
  verifyDopa: (appId: string) => `/api/dopa/verify-dopa/${appId}`,
  checkDOPASession: (appId: string) => `/api/dopa/check-dopa-session/${appId}`,
  extendSession: '/api/accounts/extend-session',
  requestLoginOtp: '/api/accounts/request-otp',
  verifyLoginOtp: '/api/accounts/verify-otp',
  resendLoginOtp: '/api/accounts/resend-otp ',
  getUserSetting: '/api/permission/get-by-user-type',
  setUserSetting: '/api/permission/set-by-user-type',
  changeStatusApplication: (appId: string) => `/api/application/change-status/${appId}`,
  getProductList: '/api/premium/retrieve/product/',
  applicationStatusList: '/api/common/list-application-status',
  applicationSearch: '/api/application/inquiry-all-application',
  transferAppOwner: '/api/application/transfer/owner',
  createApplication: '/api/application/create',
  saveApplication: '/api/application/save',
  checkPartnerDopa: (appId: string) => `/api/dopa/check-partner-dopa/${appId}`,
  callBackSummary: (appId: string) => `/api/application/callback/${appId}`,
  checkBorrowerType: '/api/application/retrieve/borrowerType',

  //partner api
  requestRedirect: '/api/partner/auth/request-redirect',
};
