import 'antd/dist/antd.less';
import ReactDOM from 'react-dom';
import App from './App';
import ErrorBoundary from 'components/error-boundary';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider as AntConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
// import setupLocatorUI from '@locator/runtime';
import i18n from './i18n';
import enUS from 'antd/lib/locale/en_US';
import './i18n';
import './assets/themes/styles/fonts.css';
import './assets/themes/styles/custom.scss';
import './assets/themes/styles/index.css';
import React from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <AntConfigProvider locale={enUS}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AntConfigProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
