import { CheckCircleFilled } from '@ant-design/icons';
import { LocationContext } from 'hooks';
import { memo, useContext, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useHistory } from 'react-router-dom';
import { ApplicationDetailResponse, ErrorResponse, TypeOfLoanEnum } from 'services/types';
import styles from './index.module.scss';
import PlusCircleOutline from 'assets/images/icons/PlusCircleOutline.svg';
import cls from 'classnames';
import PopErrorConfirm from 'components/popup/ConfirmPop';
import { useTranslation } from 'react-i18next';
import { useAutoCustomerInfoContext } from 'layouts/auto/customer-info-context';

export interface Step {
  title: string;
  url: string;
  progress?: number;
}

interface Props {
  currentStep: number;
  listMenu: Array<Step | null>;
  data?: ApplicationDetailResponse;
}
export const AutoLeftMenu = memo<Props>(({ listMenu, currentStep, data }) => {
  const history = useHistory();
  const { registerLocation } = useContext(LocationContext);
  const [isLocalErr, setIsLocalErr] = useState<boolean>(false);
  const { t } = useTranslation('customer');
  const {
    state: { autoDataCustomerInfo, autoDataApplicationInfo, autoMasterData, isAutoLoadCustomer, isAutoLoadApplication },
    actions: { setAutoDataApplicationInfo },
  } = useAutoCustomerInfoContext();
  const handleCheckStatusOfSection = (key: number) => {
    const validatePersonInfo = Boolean(
      data?.insureId && data?.contactId && data?.percentageCompletion?.initialSubmission === 100,
    );
    const validatePdpa = Boolean(data?.pdpaId?.trim());
    // const checkConditionRequired = (validatePdpa && validatePersonInfo) || false;
    if (key === 1) {
      if (validatePersonInfo) return true;
    }
    if (key === 2) {
      if (validatePersonInfo) return true;
    }
    if (key === 3) if (validatePersonInfo) return true;
    if (key === 4) if (validatePersonInfo) return true;
    if (key === 5) if (validatePersonInfo) return true;
    if (key === 6) if (validatePersonInfo) return true;
    if (key === 7) if (validatePersonInfo) return true;
    if (key === 8) if (validatePersonInfo) return true;
    if (key === 9) if (validatePersonInfo) return true;
    return false;
  };

  const validateDataBeforeSubmit = (applicationDetails: ApplicationDetailResponse | undefined) => {
    const percentageConsent =
      autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA
        ? true
        : applicationDetails?.percentageCompletion?.consent === 100;
    return (
      applicationDetails &&
      applicationDetails.percentageCompletion?.pdpa === 100 &&
      applicationDetails.percentageCompletion.personalInfo === 100 &&
      applicationDetails.percentageCompletion.insuranceDetail === 100 &&
      applicationDetails.percentageCompletion.additionalInfo === 100 &&
      applicationDetails.percentageCompletion.insuredInfo === 100 &&
      percentageConsent &&
      applicationDetails.percentageCompletion.uploadDocument === 100 &&
      applicationDetails.percentageCompletion.loanDetail === 100
    );
  };

  const SectionProgress = (props: any) => {
    if (props?.percentageCompletion == undefined) return null;
    if (!props?.percentageCompletion)
      return (
        <span className={cls('anticon', styles.circlesize)}>
          <img className={styles.circlesize} src={PlusCircleOutline} alt="CoBorrower" />
        </span>
      );
    if (props.percentageCompletion === 100) {
      return <CheckCircleFilled className={cls(props.className)} style={{ fontSize: 20, color: '#4F2A81' }} />;
    } else if (props.percentageCompletion > 0) {
      return (
        <CircularProgressbar
          value={props.percentageCompletion}
          strokeWidth={46}
          background
          backgroundPadding={4}
          className={cls(props.className, styles.circlesize)}
          styles={buildStyles({
            backgroundColor: '#4f2a81',
            pathColor: '#4f2a81',
            trailColor: '#f5f5f5',
            strokeLinecap: 'butt',
          })}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="tw-bg-input" style={{ marginTop: '-98px' }}>
        <div className={`tw-h-38 tw-bg-white ${currentStep === 1 ? 'tw-rounded-br-32' : ''}`} />
        {listMenu &&
          listMenu.map((menu, index) => {
            const isDone = handleCheckStatusOfSection(index + 1);
            return (
              <section key={menu?.title}>
                {menu && (
                  <div
                    key={menu.title}
                    className={`tw-flex tw-flex-row tw-justify-between tw-items-center tw-pl-10 tw-pr-5 tw-pt-3.5 tw-pb-2.5 tw-border-2 tw-border-red-900
                ${index + 1 === currentStep ? 'tw-bg-input' : 'tw-bg-white'} ${
                      index === currentStep - 2 ? 'tw-rounded-br-32' : ''
                    } ${index === currentStep ? 'tw-rounded-tr-32' : ''}
                `}
                    data-testid={menu.title}
                    onClick={() => {
                      /* istanbul ignore next @preserve */
                      if (isDone && menu?.url) {
                        if (index + 1 === listMenu.length) {
                          const isValid = validateDataBeforeSubmit(autoDataApplicationInfo);
                          if (!isValid) {
                            setIsLocalErr(true);
                          } else {
                            registerLocation(menu?.url || '');
                            history.push(menu?.url || '');
                          }
                        } else {
                          registerLocation(menu?.url || '');
                          history.push(menu?.url || '');
                        }
                      }
                    }}
                  >
                    <div
                      className={`tw-w-5/6 tw-text-primary tw-font-semibold tw-text-sm tw-leading-6.5 tw-cursor-pointer tw-pr-1 ${
                        index + 1 !== currentStep && !isDone ? 'tw-opacity-25' : ''
                      }`}
                    >
                      {menu.title}
                    </div>
                    {index + 1 !== currentStep && (
                      <SectionProgress
                        className={`${index + 1 !== currentStep && !isDone ? 'tw-opacity-25' : ''}`}
                        percentageCompletion={menu?.progress && +menu.progress}
                      ></SectionProgress>
                    )}
                  </div>
                )}
              </section>
            );
          })}
      </div>

      <PopErrorConfirm
        visible={isLocalErr}
        title={t('label:please_fullfill_data')}
        onOk={() => {
          setIsLocalErr(false);
        }}
        onCancel={() => {
          setIsLocalErr(false);
        }}
        contemtClassName={styles.errLocal}
        message={
          <div className="">
            <ul className="tw-text-left">
              {data?.percentageCompletion?.pdpa !== 100 && <li>{t('application:pdpa_check')}</li>}
              {data?.percentageCompletion?.personalInfo !== 100 && <li>{t('application_personalInfo')}</li>}
              {data?.percentageCompletion?.additionalInfo !== 100 && <li>{t('application:documentAddress')}</li>}
              {data?.percentageCompletion?.insuranceDetail !== 100 && <li>{t('application_insuranceDetail')}</li>}
              {data?.percentageCompletion?.insuredInfo !== 100 && <li>{t('application_insuranceInfo')}</li>}
              {autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA ? (
                <div></div>
              ) : (
                data?.percentageCompletion?.consent !== 100 && <li>{t('application_lifeInsurance')}</li>
              )}
              {data?.percentageCompletion?.uploadDocument !== 100 && <li>{t('application:upload_document')}</li>}
              {data?.percentageCompletion?.loanDetail !== 100 && <li>{t('application:check_loan_id')}</li>}
            </ul>
          </div>
        }
        showButton
        maskClosable={false}
      ></PopErrorConfirm>
    </>
  );
});
