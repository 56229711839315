import { Modal, ModalProps } from 'antd';
import { FC, memo, ReactNode, useEffect, useState } from 'react';
import styles from './index.module.scss';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/form-input/button';
import React from 'react';

interface Props extends ModalProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  message?: ReactNode;
  showButton?: boolean;
  showCancelButton?: boolean;
  button?: ReactNode;
  buttonClassName?: string;
  onCancel?: () => void;
  onOk?: () => void;
  onClickCancelBtn?: () => void;
  loading?: boolean;
  visible?: boolean;
  manualClosable?: boolean;
  destroyOnClose?: boolean;
  footerVisible?: boolean;
  okTextProp?: string;
  cancelTextProp?: string;
  contemtClassName?: string;
}

const ConfirmModal: FC<Props> = memo(
  ({
    showButton,
    showCancelButton,
    button,
    buttonClassName,
    icon,
    title,
    message,
    onOk,
    onCancel,
    loading = false,
    visible,
    children,
    manualClosable = true,
    destroyOnClose = false,
    footerVisible,
    onClickCancelBtn,
    okTextProp,
    cancelTextProp,
    contemtClassName,
    ...props
  }) => {
    const { t } = useTranslation();
    const [isVisible, setVisible] = useState<boolean>(visible || false);

    useEffect(() => {
      setVisible(visible || false);
    }, [visible]);

    return (
      <Modal
        visible={isVisible}
        closable={false}
        footer={false}
        centered
        destroyOnClose={destroyOnClose}
        maskClosable={manualClosable}
        className={cls(props.className, styles.wrapModal)}
        onCancel={() => {
          setVisible((prev) => !prev);
          onCancel?.();
        }}
        onOk={() => {
          setVisible((prev) => !prev);
          onOk?.();
        }}
        bodyStyle={{ padding: 16 }}
        {...props}
      >
        <div className={cls(contemtClassName, 'tw-py-3')}>
          <div style={{ marginBottom: 24 }}>
            <div className="tw-text-20px tw-mb-2 tw-text-primary tw-text-center tw-font-scb-medium">
              <div>{title || ''}</div>
            </div>
            <div className="tw-text-lg tw-text-center">{message || ''}</div>
          </div>
          {showButton && (
            <div className="tw-mx-auto tw-w-250px">
              <AppButton type="primary" onClick={onOk} className="tw-w-full tw-mb-3" size="large" loading={loading}>
                {okTextProp || t('button:confirm_btn')}
              </AppButton>
            </div>
          )}

          {showCancelButton && (
            <div className="tw-mx-auto tw-w-250px">
              <AppButton
                style={{
                  color: '#4F2A81',
                  border: '2px #4F2A81 solid',
                  fontWeight: 600,
                }}
                type="primary"
                onClick={onCancel}
                className="tw-w-full tw-mb-3"
                size="large"
                ghost
              >
                {cancelTextProp || t('dashboard:cancel')}
              </AppButton>
            </div>
          )}

          {children ? children : ''}
        </div>
      </Modal>
    );
  },
);

export default ConfirmModal;
