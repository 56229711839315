import { Form, FormInstance, FormItemProps } from 'antd';
import React, { memo, useState } from 'react';
import AppInput from 'components/form-input/text-input';
import { PASSPORT_NUMBER, THAI_ID_CARD } from 'helpers/regex';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Rule } from 'antd/lib/form';

interface Props extends FormItemProps {
  form: FormInstance<Object>;
  name?: string;
  label?: string;
  required?: boolean;
  requiredMsg?: string;
  regexMsg?: string;
  isForeigner: boolean;
  placeholder?: string;
  pathDetail?: string;
  onBlur?: (val: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (val: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
  inputStyle?: any;
  style?: any;
  displayHideIcon?: boolean;
  rules?: Rule[];
  maxLength?: number;
  isNumpad?: boolean;
}

export const AppIDCard = memo<Props>(
  ({
    form,
    required = true,
    requiredMsg,
    regexMsg = '',
    isForeigner = false,
    name = 'idCard',
    label = 'ID Card',
    placeholder = 'Input you id card number',
    onBlur,
    onChange,
    disabled = false,
    readOnly = false,
    inputStyle = {},
    style = {},
    displayHideIcon = true,
    rules = null,
    maxLength = -1,
    isNumpad,
    ...rest
  }) => {
    const [isVisblePassword, setVisiblePassword] = useState<boolean>(!displayHideIcon);
    const [displayValue, setDisplayValue] = useState<string>(form.getFieldValue(name));
    const { t } = useTranslation();

    const suffix = () => {
      if (isVisblePassword)
        return (
          <EyeOutlined
            className={styles.iconInput}
            onClick={() => {
              setVisiblePassword(false);
            }}
          />
        );

      return (
        <EyeInvisibleOutlined
          className={styles.iconInput}
          onClick={() => {
            setVisiblePassword(true);
          }}
        />
      );
    };

    let _isVisblePassword = isVisblePassword;

    if (!displayValue || displayValue == '') _isVisblePassword = true;

    const checkCitizenId = (value: string) => {
      let sum = 0;
      if (!checkNumberic(value)) return false;
      if (value.substring(0, 1) === '0') return false;
      if (value.length != 13) return false;
      for (let index = 0; index < 12; index++) {
        sum += parseFloat(value.charAt(index)) * (13 - index);
      }
      if ((11 - (sum % 11)) % 10 != parseFloat(value.charAt(12))) return false;
      return true;
    };
    const checkNumberic = (value: string) => {
      const RE = /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/;
      return RE.test(value);
    };

    const _style = {
      ...{
        position: 'relative',
        // marginBottom: 10,
      },
      ...style,
    };

    const _rules: Rule[] = rules
      ? rules
      : [
          () => ({
            validator(_, value) {
              if (!value || value.trim()?.length === 0) {
                if (!isForeigner) return Promise.reject(new Error(t('application:IDCARD_MUST_13_DIGITS')));
                return Promise.reject(new Error(' '));
              }
              if (!isForeigner && value?.length < 13)
                return Promise.reject(new Error(t('application:IDCARD_MUST_13_DIGITS')));

              const reg: RegExp = isForeigner ? PASSPORT_NUMBER : THAI_ID_CARD;
              if (!reg.test(value)) {
                if (!isForeigner) return Promise.reject(new Error(t('application:INVALID_IDCARD')));

                return Promise.reject(new Error(' '));
              }

              if (isForeigner) return Promise.resolve();
              if (checkCitizenId(value)) {
                return Promise.resolve();
              }
              form?.setFields([
                {
                  name: name || '',
                  errors: [''],
                },
              ]);
              return Promise.reject(new Error(t('application:INVALID_IDCARD')));
            },
          }),
        ];

    let _maxLength = maxLength ? maxLength : -1;
    if (_maxLength === -1) {
      if (isForeigner) _maxLength = 24;
      else _maxLength = 13;
    }

    return (
      <div id="IdCardNumber" style={_style}>
        {/* {displayHideIcon && suffix()} */}

        <Form.Item
          name={name}
          label={label}
          rules={_rules}
          className="tw-flex-1"
          validateTrigger={['onChange']}
          {...rest}
        >
          <AppInput
            formgroup={form}
            name={name}
            onBlur={(e) => onBlur?.(e)}
            placeholder={placeholder}
            maxLength={_maxLength}
            onChange={(e) => {
              setDisplayValue(e.target.value);
              onChange?.(e);
            }}
            disabled={disabled}
            readOnly={readOnly}
            style={inputStyle}
            // className={_isVisblePassword ? styles.visiblePassword : styles.password}
            className={styles.visiblePassword}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
            type={isNumpad ? 'tel' : 'text'}
            data-testid="AppIDCardInput"
          />
        </Form.Item>
      </div>
    );
  },
);
