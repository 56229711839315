import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import requestApi from 'helpers/requestApi';
import { url_bff_api } from 'services/endpoints';
import { APIErrorResponse, ApplicationDetailResponse, RequestChannelType } from 'services/types';

export const queryApplicationInfoKey = 'query-application-info';

export type CustomerInformationOptions = UseQueryOptions<
  AnnotationJobQueryFn,
  APIErrorResponse,
  ApplicationDetailResponse
>;

export type AnnotationJobQueryFn = () => ApplicationDetailResponse;

export function useApplicationInfo(id: string, options?: CustomerInformationOptions) {
  return useQuery<AnnotationJobQueryFn, APIErrorResponse, ApplicationDetailResponse>(
    [queryApplicationInfoKey, id],
    () => requestApi.get(null, url_bff_api.applicationDetail(id)).then((res: any) => res.data),
    options,
  );
}

export interface ApplicationQueryParams {
  data: {
    id: string;
  };
  channel?: RequestChannelType;
}

export type CustomerInformationLazyOptions = UseMutationOptions<
  ApplicationDetailResponse,
  APIErrorResponse,
  ApplicationQueryParams
>;

export function useApplicationInfoLazyQuery(options?: CustomerInformationLazyOptions) {
  return useMutation<ApplicationDetailResponse, APIErrorResponse, ApplicationQueryParams>(
    queryApplicationInfoKey,
    (params) =>
      requestApi.get(params.channel, url_bff_api.applicationDetail(params.data.id)).then((res: any) => res.data),
    options,
  );
}
