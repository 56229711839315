import { useQueryParams } from 'hooks/useQueryParams';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetailResponse, TypeOfLoanEnum } from 'services/types';
import { useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { UseHookError } from 'hooks/useHookError';
import { LeftMenu } from 'components/left-menu';
import cls from 'classnames';
import { getValueIfDefined } from 'helpers/common';
import { useCustomerInfoContext } from 'layouts/main/customer-info-context';
import { mappingStepNumber } from 'helpers/constants';

export interface Props {
  className?: string;
  currentStep?: number;
  appIsCall?: boolean;
  customerIsCall?: boolean;
  reCalculatePremium?: boolean;
}

export interface Step {
  title: string;
  url: string;
  progress?: number;
}

const validateJump = (link: string, condition: boolean) => {
  if (condition && link) {
    return link;
  } else {
    return '';
  }
};

export const SideMenuComponent: React.FC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const {
    state: { dataCustomerInfo, dataApplicationInfo },
  } = useCustomerInfoContext();
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  //GET AppId from URL
  //CASE A
  const getParams = useQueryParams();

  const temp_id = getParams.get('appId');
  const getAppId = getValueIfDefined(temp_id, '');

  //CASE B
  const { appId } = useParams<{ appId: string }>();
  const [onErrors, setOnErrors] = useState<any>({});

  useEffect(() => {
    if (location.pathname) {
      const stepItem = mappingStepNumber.find((item) => location.pathname.includes(item.path));
      if (stepItem && stepItem.number) setCurrentStep(stepItem.number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const validatePersonInfo = Boolean(dataApplicationInfo?.insureId && dataApplicationInfo?.contactId);
  const validateInsurance = Boolean(
    dataApplicationInfo?.beneficiaryId && dataApplicationInfo?.insuranceSummaryComplete === 'Y',
  );
  const validateInsuranceByPass = true;
  const validateFatca = true;

  const [stepList, setStepList] = useState<Array<Step | null>>([]);

  const urlValidateFatca = () => {
    if (validateFatca) {
      if (dataApplicationInfo?.consentId) {
        return AppRoutes.consentParams({
          appId,
          consentId: dataApplicationInfo?.consentId,
          sumInsuredFlag: dataApplicationInfo?.sumInsuredFlag,
        });
      }
      return AppRoutes.consentParams({ appId: appId });
    }

    return '';
  };

  useEffect(() => {
    if (dataCustomerInfo?.applicationType === TypeOfLoanEnum.HomeLoan) {
      setStepList([
        {
          title: t('customer:customerInfor'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.personalInfo) || 0,
          url: AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:application_loanAccDetail'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.loanDetail) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.loanDetailParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:PDPA'),
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.consentPdpaParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),

          progress: (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.pdpa) || 0,
        },
        {
          title: t('application:documentAddress'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.additionalInfo) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.additionalInfoParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:InsuranceDetail'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.insuranceDetail) ||
            0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.customerInsuranceInfoParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:question'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.healthQuestion) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.healthQuestionParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:requesterInfor'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.insuredInfo) || 0,

          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.revenueTaxParams({ appId: appId })
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:letterConfirm'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.consent) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? urlValidateFatca()
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('application:upload_document'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.uploadDocument) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.uploadDocumentParam({ appId: appId })
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:success'),
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.summaryParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCustomerInfo, appId, dataApplicationInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {currentStep && (
        <div>
          {/* <div className={cls('tw-flex tw-flex-row tw-w-screen')}> */}
          <div className={cls('tw-flex tw-flex-row')}>
            <div className="tw-hidden md:tw-block tw-bg-white tw-" style={{ minHeight: 'calc(100vh - 98px)' }}>
              <LeftMenu currentStep={currentStep} listMenu={stepList} data={dataApplicationInfo} />
              <div className="banner-promote-wrapper">
                <img className="mortgage-omne-banner" alt="onme-left-side" />
              </div>
            </div>
          </div>
        </div>
      )}
      <UseHookError err={onErrors} />
    </>
  );
});
