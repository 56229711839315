import { useQueryParams } from 'hooks/useQueryParams';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetailResponse, ApplicationStatusEnum, ErrorResponse, TypeOfLoanEnum } from 'services/types';
import styles from './index.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import Spinner from 'components/spinner';
import { UseHookError } from 'hooks/useHookError';
import { LocationContext } from 'hooks';
import { getValueIfDefined } from 'helpers/common';
import { mappingStepNumber } from 'helpers/constants';
import { useCustomerInfoContext } from 'layouts/main/customer-info-context';
import useCheckDOPASessionMutation from 'services/mutations/useCheckDOPASessionMutation';
import PopErrorConfirm from 'components/popup/ConfirmPop';
import DOPAModal from 'components/dopa-popup';

export interface Props {
  className?: string;
  currentStep?: number;
  appIsCall?: boolean;
  customerIsCall?: boolean;
  reCalculatePremium?: boolean;
}

export interface Step {
  title: string;
  url: string;
  progress?: number;
}

const validateJump = (link: string, condition: boolean) => {
  if (condition && link) {
    return link;
  } else {
    return '';
  }
};

export const BreadcrumbComponent: React.FC<Props> = React.memo((props) => {
  const { className, children, appIsCall, customerIsCall, reCalculatePremium } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { registerLocation } = useContext(LocationContext);
  const {
    state: { dataCustomerInfo, dataApplicationInfo },
  } = useCustomerInfoContext();
  //GET AppId from URL
  //CASE A
  const getParams = useQueryParams();
  const location = useLocation();

  const temp_id = getParams.get('appId');
  const getAppId = getValueIfDefined(temp_id, '');

  const [currentStep, setCurrentStep] = useState<number | null>(null);

  //CASE B
  const { appId } = useParams<{ appId: string }>();
  const [onErrors, setOnErrors] = useState<any>({});
  const [isLocalErr, setIsLocalErr] = useState<boolean>(false);
  const [isCheckDOPA, setIsCheckDOPA] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const dopaSessionQuery = useCheckDOPASessionMutation();

  useEffect(() => {
    if (location.pathname) {
      const stepItem = mappingStepNumber.find((item) => location.pathname.includes(item.path));
      if (stepItem && stepItem.number) setCurrentStep(stepItem.number);
    }
  }, [location.pathname]);

  const validatePersonInfo = Boolean(
    dataApplicationInfo?.insureId && dataApplicationInfo?.contactId && dataApplicationInfo?.phoneComplete === 'Y',
  );
  const validateInsurance = Boolean(
    dataApplicationInfo?.beneficiaryId && dataApplicationInfo?.insuranceSummaryComplete === 'Y',
  );
  const validateInsuranceByPass = true;
  const validateHealthQuestion = Boolean(dataApplicationInfo?.healthQuestionId);
  const validateFatca = Boolean(dataApplicationInfo?.fatcaId);

  const [stepList, setStepList] = useState<Step[]>([]);

  const urlValidateFatca = () => {
    if (validateFatca) {
      if (dataApplicationInfo?.consentId) {
        return AppRoutes.consentParams({
          appId,
          consentId: dataApplicationInfo?.consentId,
          sumInsuredFlag: dataApplicationInfo?.sumInsuredFlag,
        });
      }
      return AppRoutes.consentParams({ appId: appId });
    }

    return '';
  };

  useEffect(() => {
    if (dataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoLoan) {
      setStepList([
        {
          title: t('customer:PDPA'),
          progress: dataApplicationInfo.percentageCompletion.pdpa || 0,
          url: AppRoutes.consentPdpaParams(appId),
        },
        {
          title: t('customer:customerInfor'),
          progress: dataApplicationInfo.percentageCompletion.personalInfo || 0,
          url: validateJump(
            AppRoutes.personalInfoParams({ appId: appId }),
            validatePersonInfo || !!dataApplicationInfo?.pdpaId,
          ),
        },
        {
          title: t('customer:InsuranceDetail'),
          progress: dataApplicationInfo.percentageCompletion.insuranceDetail || 0,
          url: validateJump(AppRoutes.customerInsuranceInfoParams(appId), validateInsurance || validatePersonInfo),
        },
        {
          title: t('customer:requesterInfor'),
          progress: dataApplicationInfo.percentageCompletion.insuredInfo || 0,
          url: validateJump(AppRoutes.revenueTaxParams({ appId: appId }), validateInsurance),
        },
        {
          title: t('customer:letterConfirm'),
          progress: dataApplicationInfo.percentageCompletion.consent || 0,
          url: urlValidateFatca(),
        },
        {
          title: t('application:upload_document'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.uploadDocument) || 0,
          url: validateJump(
            AppRoutes.uploadDocumentParam({ appId: appId }),
            validateInsuranceByPass || validatePersonInfo,
          ),
        },
        {
          title: t('customer:application_loanAccDetail'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.loanDetail) || 0,
          url: validateJump(AppRoutes.loanDetailParams(appId), validateInsuranceByPass || validatePersonInfo),
        },
        { title: t('customer:success'), url: '' },
      ]);
    } else {
      setStepList([
        {
          title: t('customer:customerInfor'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.personalInfo) || 0,
          url: AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:application_loanAccDetail'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.loanDetail) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.loanDetailParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:PDPA'),
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.consentPdpaParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),

          progress: (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.pdpa) || 0,
        },
        {
          title: t('application:documentAddress'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.additionalInfo) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.additionalInfoParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:InsuranceDetail'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.insuranceDetail) ||
            0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.customerInsuranceInfoParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:question'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.healthQuestion) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.healthQuestionParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:requesterInfor'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.insuredInfo) || 0,

          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.revenueTaxParams({ appId: appId })
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:letterConfirm'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.consent) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? urlValidateFatca()
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('application:upload_document'),
          progress:
            (dataApplicationInfo?.percentageCompletion && dataApplicationInfo.percentageCompletion.uploadDocument) || 0,
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.uploadDocumentParam({ appId: appId })
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
        {
          title: t('customer:success'),
          url:
            validateInsuranceByPass && validatePersonInfo
              ? AppRoutes.summaryParams(appId)
              : AppRoutes.personalInfoParams({ appId: appId }),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCustomerInfo, appId, dataApplicationInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckStatusOfSection = (key: number) => {
    const validatePersonInfo = Boolean(dataApplicationInfo?.insureId && dataApplicationInfo?.contactId);
    const validatePdpa = Boolean(dataApplicationInfo?.pdpaId?.trim());
    const checkConditionRequired = (validatePdpa && validatePersonInfo) || false;
    if (key === 1) {
      if (validatePersonInfo) return true;
    }
    if (key === 2) {
      if (validatePersonInfo) return true;
    }
    if (key === 3) if (validatePersonInfo) return true;
    if (key === 4) if (validatePersonInfo) return true;
    if (key === 5) if (validatePersonInfo) return true;
    if (key === 6) if (validatePersonInfo) return true;
    if (key === 7) if (validatePersonInfo) return true;
    if (key === 8) if (validatePersonInfo) return true;
    if (key === 9) if (validatePersonInfo) return true;
    if (key === 10) if (validatePersonInfo) return true;
    return false;
  };

  const checkDOPASession = async (item: any) => {
    await dopaSessionQuery.mutateAsync(
      {
        data: { appId: dataApplicationInfo?.id },
      },
      {
        onSuccess: (response: any) => {
          if (response.required === true) {
            setIsCheckDOPA(true);
            setSelectedItem((prev: any) => item?.url || null);
            return;
          } else {
            registerLocation(item?.url || '');
            item?.url && history.push(item?.url || '');
          }
        },
        onError: (error: ErrorResponse) => {},
      },
    );
  };

  const validateDataBeforeSubmit = (applicationDetails: ApplicationDetailResponse | undefined) => {
    const hasHealthQuestion =
      applicationDetails?.applicationType === TypeOfLoanEnum.HomeLoan
        ? applicationDetails.percentageCompletion?.healthQuestion === 100
        : true;
    return (
      applicationDetails &&
      applicationDetails.percentageCompletion?.pdpa === 100 &&
      applicationDetails.percentageCompletion.personalInfo === 100 &&
      applicationDetails.percentageCompletion.insuranceDetail === 100 &&
      applicationDetails.percentageCompletion.additionalInfo === 100 &&
      hasHealthQuestion &&
      applicationDetails.percentageCompletion.insuredInfo === 100 &&
      applicationDetails.percentageCompletion.consent === 100 &&
      applicationDetails.percentageCompletion.uploadDocument === 100 &&
      applicationDetails.percentageCompletion.loanDetail === 100
    );
  };

  const healthQuestionCompleted =
    dataApplicationInfo?.applicationType === TypeOfLoanEnum.HomeLoan
      ? dataApplicationInfo.percentageCompletion?.healthQuestion === 100
      : true;

  const widthPerStep = 95 / (stepList?.length - 2);
  if (currentStep == null) return <Spinner height={'calc(100vh - 100px)'} />;

  return (
    <>
      {currentStep != null && dataCustomerInfo && dataApplicationInfo && (
        <div className={`tw-min-h-full ${className ? className : ''}`}>
          <div className={`${styles.stepsWraper}`} style={{ lineHeight: '1.5715' }}>
            <div className={styles.stepList}>
              {stepList?.map((item, index) => {
                const isDisabled = !Boolean(dataApplicationInfo?.insureId && dataApplicationInfo?.contactId);

                if (item?.title) {
                  return (
                    <div
                      key={index}
                      className={styles.stepWraper}
                      style={{
                        width: widthPerStep + '%',
                      }}
                      onClick={() => {
                        if (isDisabled) return false;

                        if (item?.url) {
                          // registerLocation(item?.url || '');
                          // history.push(item?.url || '');
                          if (index + 1 === 10) {
                            const isValid = validateDataBeforeSubmit(dataApplicationInfo);
                            if (!isValid) {
                              setIsLocalErr(true);
                            } else {
                              checkDOPASession(item);
                            }
                          } else {
                            if ([3, 4, 5, 6, 7, 8, 9].includes(index + 1)) {
                              checkDOPASession(item);
                            } else {
                              registerLocation(item?.url || '');
                              history.push(item?.url || '');
                            }
                          }
                        }
                      }}
                    >
                      <div
                        className={
                          index + 1 <= currentStep ||
                          ![ApplicationStatusEnum.DRAFT, ApplicationStatusEnum.QUOTATION].includes(
                            dataCustomerInfo?.status,
                          )
                            ? `${styles.stepTitle} ${styles.customStepActive}`
                            : `${styles.stepTitle}`
                        }
                      >
                        <span>{item?.title}</span>
                      </div>
                      <div className={styles.step}>
                        <div
                          className={
                            index + 1 <= currentStep ||
                            ![ApplicationStatusEnum.DRAFT, ApplicationStatusEnum.QUOTATION].includes(
                              dataCustomerInfo?.status,
                            )
                              ? `${styles.customStep} ${styles.customStepActive}`
                              : `${styles.customStep}`
                          }
                        >
                          <div className={styles.customInsizeStep}></div>
                        </div>
                        <div
                          className={
                            index + 1 < currentStep ||
                            ![ApplicationStatusEnum.DRAFT, ApplicationStatusEnum.QUOTATION].includes(
                              dataCustomerInfo?.status,
                            )
                              ? `${styles.customBar} ${styles.customStepActive}`
                              : `${styles.customBar}`
                          }
                        ></div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
      <DOPAModal
        onCancel={() => setIsCheckDOPA(false)}
        isDashboard={true}
        appId={dataApplicationInfo?.id || ''}
        visible={isCheckDOPA}
        showButton={true}
        navigationURL={selectedItem}
      ></DOPAModal>

      <PopErrorConfirm
        visible={isLocalErr}
        title={t('label:please_fullfill_data')}
        onOk={() => {
          setIsLocalErr(false);
        }}
        onCancel={() => {
          setIsLocalErr(false);
        }}
        contemtClassName={styles.errLocal}
        message={
          <div className="">
            <ul className="tw-text-left">
              {dataApplicationInfo?.percentageCompletion?.pdpa !== 100 && <li>{t('application:pdpa_check')}</li>}
              {dataApplicationInfo?.percentageCompletion?.personalInfo !== 100 && (
                <li>{t('customer:application_personalInfo')}</li>
              )}
              {dataApplicationInfo?.percentageCompletion?.additionalInfo !== 100 && (
                <li>{t('application:documentAddress')}</li>
              )}
              {dataApplicationInfo?.percentageCompletion?.insuranceDetail !== 100 && (
                <li>{t('customer:application_insuranceDetail')}</li>
              )}
              {!healthQuestionCompleted && <li>{t('customer:application_healthQuestion')}</li>}
              {dataApplicationInfo?.percentageCompletion?.insuredInfo !== 100 && (
                <li>{t('customer:application_insuranceInfo')}</li>
              )}
              {dataApplicationInfo?.percentageCompletion?.consent !== 100 && (
                <li>{t('customer:application_lifeInsurance')}</li>
              )}
              {dataApplicationInfo?.percentageCompletion?.uploadDocument !== 100 && (
                <li>{t('application:upload_document')}</li>
              )}
              {dataApplicationInfo?.percentageCompletion?.loanDetail !== 100 && (
                <li>{t('application:check_loan_id')}</li>
              )}
            </ul>
          </div>
        }
        showButton
        maskClosable={false}
      ></PopErrorConfirm>
      <UseHookError err={onErrors} />
    </>
  );
});
