import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import requestApi from 'helpers/requestApi';
import { url_bff_api } from 'services/endpoints';
import { APIErrorResponse, CustomerInfoResponse, RequestChannelType } from 'services/types';
import { decryptResponseData } from 'helpers/common';

export const queryCustomerInfoKey = 'query-customer-info';

export type CustomerInformationOptions = UseQueryOptions<CustomerInfoQueryFn, APIErrorResponse, CustomerInfoResponse>;

export interface GetImageListInPathQueryParams {
  data: {
    appId: string;
    sessionId: string;
  };
}
export type CustomerInfoQueryFn = () => CustomerInfoResponse;

export interface UrlParam {
  appId: string;
  sessionId: string;
  reCalculatePremium?: boolean;
}
interface QueryParams {
  data: {
    appId: string;
    sessionId: string;
    reCalculatePremium?: boolean;
  };
  channel?: RequestChannelType;
}
export function useCustomerInfo(params: UrlParam, options?: CustomerInformationOptions) {
  return useQuery<CustomerInfoQueryFn, APIErrorResponse, CustomerInfoResponse>(
    [queryCustomerInfoKey, params],
    () =>
      params?.sessionId
        ? requestApi
            .get(null, url_bff_api.customerProfile(params), undefined, { 'session-id': params.sessionId })
            .then(async (res: any) => {
              return JSON.parse(JSON.parse(await decryptResponseData(res.data)));
            })
        : requestApi.get(null, url_bff_api.customerProfile(params)).then(async (res: any) => {
            return JSON.parse(JSON.parse(await decryptResponseData(res.data)));
          }),
    options,
  );
}

export type CustomerInformationLazyOptions = UseMutationOptions<CustomerInfoResponse, APIErrorResponse, QueryParams>;

export function useCustomerInfoLazyQuery(options?: CustomerInformationLazyOptions) {
  return useMutation<CustomerInfoResponse, APIErrorResponse, QueryParams>((params) => {
    if (params?.data?.sessionId) {
      return requestApi
        .get(params.channel, url_bff_api.customerProfile(params.data), undefined, {
          'session-id': params?.data?.sessionId,
        })
        .then(async (res) => {
          return JSON.parse(JSON.parse(await decryptResponseData(res.data)));
        });
    } else {
      return requestApi.get(params.channel, url_bff_api.customerProfile(params.data)).then(async (res: any) => {
        return JSON.parse(JSON.parse(await decryptResponseData(res.data)));
      });
    }
  }, options);
}
