import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Modal, ModalProps, Row, Form } from 'antd';
import { BondsmanActiveIcon, BondsmanIcon, CoBorrowerIcon, MainBorrowerIcon } from 'assets/images/icons';
import CoBorrower from 'assets/images/icons/CoBorrower.svg';
import MainBorrower from 'assets/images/icons/MainBorrower.svg';
import cls from 'classnames';
import AppButton from 'components/form-input/button';
import { FC, memo, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import useApplicationMutation from 'services/mutations/useApplicationMutation';
import { TypeOfBorrowerEnum, TypeOfLoanEnum, ErrorResponse } from 'services/types';
import styles from './index.module.scss';
import { UseHookError } from 'hooks/useHookError';
import { useQueryClient } from 'react-query';
import { AppRoutes } from 'helpers';
import { clearUserSession, setDataSession } from 'helpers/common';
import AppInput from 'components/form-input/text-input';
import { SCB_APPLICATION_NUM } from 'helpers/regex';
import useCheckBorrowerType from 'services/mutations/useCheckBorrowerType';

interface Props extends ModalProps {
  icon?: ReactNode;
  title?: string;
  message?: ReactNode;
  showButton?: boolean;
  button?: ReactNode;
  buttonClassName?: string;
  onCancel?: () => void;
  onOk?: () => void;
  onClickCancelBtn?: () => void;
  loading?: boolean;
  visible?: boolean;
  manualClosable?: boolean;
  footerVisible?: boolean;
  titleProps?: string;
  typeOfLoan?: TypeOfLoanEnum | string;
  typeOfBorrower?: number;
  scbAppNo?: string;
}

interface DataSelectType {
  id: number;
  title: string;
  icon: ReactNode;
  type: TypeOfBorrowerEnum;
}

const HomeMortgage: FC<Props> = memo(
  ({
    showButton,
    button,
    buttonClassName,
    icon,
    title,
    message,
    onOk,
    onCancel,
    loading,
    visible,
    children,
    manualClosable,
    footerVisible,
    onClickCancelBtn,
    titleProps,
    typeOfBorrower,
    typeOfLoan,
    scbAppNo,
    ...props
  }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const client = useQueryClient();
    const [isVisible, setVisible] = useState<boolean>(visible || false);
    const [isSelect, setSelect] = useState<number>(0);
    const [isCheckBorrow, setCheckBorrow] = useState<boolean>(false);
    const [onErrors, setOnErrors] = useState<any>({});
    const [form] = Form.useForm();
    const [validateSubmit, setValidateSubmit] = useState<boolean>(false);
    const [isScbValue, setScbValue] = useState<string>('');
    const checkBorrowerType = useCheckBorrowerType();

    useEffect(() => {
      setVisible(visible || false);
    }, [visible]);

    useEffect(() => {
      if (typeOfBorrower === 2) {
        setSelect(2);
      }
    }, [typeOfBorrower]);

    const dataSelect: DataSelectType[] = [
      {
        id: 1,
        title: typeOfLoan === TypeOfLoanEnum.HomeLoan ? t('home:mainBorrower') : t('home:buyer'),
        icon: <img src={MainBorrower} alt="MainBorrower" />,
        type: TypeOfBorrowerEnum.BORROWER,
      },
      {
        id: 2,
        title: typeOfLoan === TypeOfLoanEnum.HomeLoan ? t('home:coBorrower') : t('home:bondsman'),
        icon: <img src={CoBorrower} alt="CoBorrower" />,
        type: typeOfLoan === TypeOfLoanEnum.HomeLoan ? TypeOfBorrowerEnum.CO_BORROWER : TypeOfBorrowerEnum.GUARANTOR,
      },
    ];

    const handleIconPicked = (id: number) => {
      return (
        <div className={cls('tw-flex tw-justify-center tw-items-center')}>
          {id === 1 ? (
            <MainBorrowerIcon style={{ color: id === isSelect ? '#4F2A81' : '#C4C4C4' }} />
          ) : typeOfLoan === TypeOfLoanEnum.HomeLoan ? (
            <CoBorrowerIcon style={{ color: id === isSelect ? '#4F2A81' : '#C4C4C4' }} />
          ) : id === isSelect ? (
            <BondsmanActiveIcon />
          ) : (
            <BondsmanIcon />
          )}
        </div>
      );
    };

    const { mutate: handleApplication, isLoading: resetApplicationLoading } = useApplicationMutation({
      onSuccess: (res) => {
        setVisible(false);
        setOnErrors({});
        clearUserSession();
        client.clear();
        if (!['production'].includes(process.env.REACT_APP_TARGET_ENV as any)) {
          window.location.assign(
            res?.redirectUrl + `&cltoken=${res?.token}&endpoint=${process.env.REACT_APP_ENDPOINT}`,
          );
        } else {
          window.location.assign(res?.redirectUrl + `&cltoken=${res?.token}`);
        }
      },
      onError: (err: any) => {
        setOnErrors(err || {});
      },
    });

    const handleRedirectToCL = () => {
      if (typeOfLoan && isSelect > 0) {
        handleApplication({
          data: {
            appType: typeOfLoan as TypeOfLoanEnum,
            borrowerType:
              isSelect === 1
                ? TypeOfBorrowerEnum.BORROWER
                : typeOfLoan === TypeOfLoanEnum.HomeLoan
                ? TypeOfBorrowerEnum.CO_BORROWER
                : TypeOfBorrowerEnum.GUARANTOR,
          },
        });
      }
    };

    const handleValuesChanges = (changedValues: any, values: any) => {
      if (Boolean(RegExp(SCB_APPLICATION_NUM).test(values?.scbAppNo?.toString()))) {
        setValidateSubmit(true);
        setScbValue(values.scbAppNo);
        setCheckBorrow(false);
      } else {
        setValidateSubmit(false);
        setCheckBorrow(false);
      }
    };

    useEffect(() => {
      if (scbAppNo) {
        form.setFields([
          {
            name: 'scbAppNo',
            value: scbAppNo,
          },
        ]);
        setScbValue(scbAppNo);
        setValidateSubmit(true);
      }
    }, [form, scbAppNo]);

    return (
      <>
        <Modal
          visible={isVisible}
          title={title ?? ''}
          closable={manualClosable}
          footer={false}
          centered
          destroyOnClose={true}
          className={cls(props.className, styles.wrapModal)}
          onCancel={() => {
            setVisible((prev) => !prev);
            onCancel?.();
            setSelect(0);
            setCheckBorrow(false);
          }}
          bodyStyle={{ padding: 16 }}
          {...props}
        >
          <div>
            <div className="tw-text-xl tw-font-noto-medium tw-mb-2 tw-text-primary tw-text-center">{titleProps}</div>
            <div className="tw-text-13px tw-mb-6 tw-text-center tw-font-noto-regular">
              {typeOfLoan === TypeOfLoanEnum.HomeLoan
                ? t('home:chooseOneBorrowStatus')
                : t('home:chooseOneBuyerStatus')}
            </div>
          </div>

          <Row gutter={16} className={`tw-mb-${isSelect === 2 ? '20px' : '40px'}`}>
            {dataSelect &&
              dataSelect.map((item: DataSelectType) => {
                return (
                  <Col span={12} key={item.id}>
                    <div
                      id="productselect"
                      className={cls(
                        styles.products,
                        'tw-border-2 tw-border-solid tw-relative ',
                        {
                          'tw-text-default/25': isSelect !== item.id,
                          'tw-text-primary': isSelect === item.id,
                          'tw-border-default': isSelect !== item.id,
                          'tw-border-primary': isSelect === item.id,
                        },
                        { disabled: scbAppNo ? (isSelect !== item.id ? true : false) : false },
                      )}
                      key={item.id}
                      onClick={() => setSelect(item.id)}
                    >
                      {isSelect === item.id && <CheckCircleFilled className={styles.pickedIcon} />}
                      <div className="tw-text-center tw-grid tw-grid-rows-2 tw-gap-3">
                        {handleIconPicked(item.id)}
                        <div className="tw-text-center tw-text-base tw-max-w-99px tw-break-words tw-font-noto-medium">
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <div className={styles.formAppNo}>
            {isSelect === 2 && (
              <Form preserve={false} layout="vertical" form={form} onValuesChange={handleValuesChanges}>
                <Form.Item
                  label={t('customer:fillInSCBLoanNumber')}
                  name="scbAppNo"
                  validateTrigger={['onBlur', 'onChange']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: ' ',
                    },
                    {
                      pattern: SCB_APPLICATION_NUM,
                      message: 'เลขที่ใบคำขอสินเชื่อไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง',
                    },
                  ]}
                >
                  <AppInput
                    maxLength={15}
                    placeholder={t('customer:placeHolderSCBLoanNumber')}
                    name="scbAppNo"
                    formgroup={form}
                    type="tel"
                    disabled={scbAppNo ? true : false}
                    data-testid="scbAppNo"
                    className={styles.explainError}
                  />
                </Form.Item>
                {isCheckBorrow && <div className={styles.scbnoErr}>{t('customer:text_scbno_err')}</div>}
              </Form>
            )}
          </div>
          <div className="tw-mx-auto tw-w-250px">
            <AppButton
              type="primary"
              className="tw-w-full tw-font-noto-regular btn-home-navigateClCal"
              size="large"
              disabled={isSelect === 2 && !validateSubmit}
              onClick={async () => {
                const paramData = {
                  borrowerType:
                    isSelect === 1
                      ? TypeOfBorrowerEnum.BORROWER
                      : typeOfLoan === TypeOfLoanEnum.HomeLoan
                      ? TypeOfBorrowerEnum.CO_BORROWER
                      : TypeOfBorrowerEnum.GUARANTOR,
                };
                if (isSelect === 2) {
                  await checkBorrowerType.mutateAsync(
                    { data: { scbApplicationNo: isScbValue } },
                    {
                      onSuccess: (response: any) => {
                        setOnErrors({});
                        if (response?.applicationList) {
                          const checkBorrow = response.applicationList?.filter(
                            (item: any) => item.borrowType === TypeOfBorrowerEnum.BORROWER,
                          );
                          if (checkBorrow.length > 0) {
                            onOk?.();
                            setSelect(0);
                            setDataSession('create_scb_loan_no', isScbValue);
                            setCheckBorrow(false);
                            history.push(AppRoutes.clCalculationParams(paramData));
                          } else {
                            setCheckBorrow(true);
                            setSelect(2);
                          }
                        }
                      },
                      onError: (err: any) => {
                        setOnErrors(err || {});
                      },
                    },
                  );
                } else {
                  onOk?.();
                  setSelect(0);
                  setCheckBorrow(false);
                  history.push(AppRoutes.clCalculationParams(paramData));
                }
              }}
            >
              {t('home:nextToFeeStep')}
            </AppButton>
            {process.env.REACT_APP_ENDPOINT === 'SIT' && (
              <AppButton
                style={{ marginBottom: '16px' }}
                type="primary"
                className="tw-w-full btn-home-navigateClCal"
                size="large"
                disabled={isSelect === 0}
                onClick={handleRedirectToCL}
                loading={resetApplicationLoading}
              >
                External CL Cal
              </AppButton>
            )}
          </div>
        </Modal>
        <UseHookError err={onErrors} />
      </>
    );
  },
);

export default HomeMortgage;
