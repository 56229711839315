import React, { createContext, useContext, useState } from 'react';

export const AnnouncementContext = createContext<any>('');

export function AnnouncementContextProvider({ children }: any) {
  const [announcement, setAnnouncement] = useState(localStorage.getItem('announcement-data') || '[]');

  return (
    <AnnouncementContext.Provider value={{ announcement, setAnnouncement }}>{children}</AnnouncementContext.Provider>
  );
}

export const useAnnouncementContext = () => useContext(AnnouncementContext);
