import { useQueryParams } from 'hooks/useQueryParams';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetailResponse, TypeOfLoanEnum } from 'services/types';
import { useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { UseHookError } from 'hooks/useHookError';
import { AutoLeftMenu } from 'components/auto-left-menu';
import cls from 'classnames';
import { getValueIfDefined } from 'helpers/common';
import { useAutoCustomerInfoContext } from 'layouts/auto/customer-info-context';
import { mappingAutoStepNumber, mappingAutoStepNumberPA } from 'helpers/constants';

export interface Props {
  className?: string;
  currentStep?: number;
  appIsCall?: boolean;
  customerIsCall?: boolean;
  reCalculatePremium?: boolean;
}

export interface Step {
  title: string;
  url: string;
  progress?: number;
}
/* istanbul ignore next @preserve */
const validateJump = (link: string, condition: boolean) => {
  if (condition && link) {
    return link;
  } else {
    return '';
  }
};

export const AutoSideMenuComponent: React.FC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const {
    state: { autoDataCustomerInfo, autoDataApplicationInfo },
  } = useAutoCustomerInfoContext();
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  //GET AppId from URL
  //CASE A
  const getParams = useQueryParams();

  const temp_id = getParams.get('appId');
  const getAppId = getValueIfDefined(temp_id, '');

  //CASE B
  const { appId } = useParams<{ appId: string }>();
  const [onErrors, setOnErrors] = useState<any>({});
  /* istanbul ignore next @preserve */
  useEffect(() => {
    if (location.pathname) {
      const stepMapping =
        autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA
          ? mappingAutoStepNumberPA
          : mappingAutoStepNumber;
      const stepItem = stepMapping.find((item) => location.pathname.includes(item.path));
      if (stepItem && stepItem.number) setCurrentStep(stepItem.number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const validatePersonInfo = Boolean(autoDataApplicationInfo?.insureId && autoDataApplicationInfo?.contactId);
  const validateInsurance = Boolean(
    autoDataApplicationInfo?.beneficiaryId && autoDataApplicationInfo?.insuranceSummaryComplete === 'Y',
  );
  const validateInsuranceByPass = true;
  const validateFatca = true;

  const [stepList, setStepList] = useState<Array<Step | null>>([]);

  // const urlValidateFatca = () => {
  //   if (validateFatca) {
  //     if (autoDataApplicationInfo?.consentId) {
  //       return AppRoutes.consentParams({
  //         appId,
  //         consentId: autoDataApplicationInfo?.consentId,
  //         sumInsuredFlag: autoDataApplicationInfo?.sumInsuredFlag,
  //       });
  //     }
  //     return AppRoutes.consentParams({ appId: appId });
  //   }

  //   return '';
  // };

  useEffect(() => {
    const stepList = [
      {
        title: t('auto:autoPersonalInfo'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion &&
            autoDataApplicationInfo.percentageCompletion.personalInfo) ||
          0,
        url: AppRoutes.autoPersonalInfoParams(appId),
      },
      {
        title: t('auto:autoLoanInfo'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion && autoDataApplicationInfo.percentageCompletion.loanDetail) ||
          0,
        url: AppRoutes.autoLoanInfoParams(appId),
      },
      {
        title: t('auto:autoCustomerInsuranceInfo'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion &&
            autoDataApplicationInfo.percentageCompletion.insuranceDetail) ||
          0,
        url: AppRoutes.autoCustomerInsuranceInfoParams(appId),
      },
      {
        title: t('auto:autoPDPA'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion && autoDataApplicationInfo.percentageCompletion.pdpa) || 0,
        url: AppRoutes.autoPDPAParams(appId),
      },
      {
        title: t('auto:autoAdditionalInfo'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion &&
            autoDataApplicationInfo.percentageCompletion.additionalInfo) ||
          0,
        url: AppRoutes.autoAutoAdditionalInfoParam(appId),
      },
      {
        title: t('auto:autoCustomerBeneficairy'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion && autoDataApplicationInfo.percentageCompletion.insuredInfo) ||
          0,
        url: AppRoutes.autoCustomerBeneficairyParams(appId),
      },
      {
        title: t('auto:autoConsent'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion && autoDataApplicationInfo.percentageCompletion.consent) || 0,
        url: AppRoutes.autoConsentParams({ appId }),
      },
      {
        title: t('auto:autoUploadDocument'),
        progress:
          (autoDataApplicationInfo?.percentageCompletion &&
            autoDataApplicationInfo.percentageCompletion.uploadDocument) ||
          0,
        url: AppRoutes.autoUploadDocumentParam(appId),
      },
      {
        title: t('auto:autoSummary'),
        url: AppRoutes.autoSummaryParams(appId),
      },
    ];
    if (autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA) {
      const textConcent = t('auto:autoConsent');
      const indexConcent = stepList.map((item) => item.title).indexOf(textConcent);
      if (indexConcent > -1) {
        stepList.splice(indexConcent, 1);
      }
    }
    setStepList(stepList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDataCustomerInfo, appId, autoDataApplicationInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {currentStep && (
        <div>
          <div className={cls('tw-flex tw-flex-row')}>
            <div className="tw-hidden md:tw-block tw-bg-white tw-" style={{ minHeight: 'calc(100vh - 98px)' }}>
              <AutoLeftMenu currentStep={currentStep} listMenu={stepList} data={autoDataApplicationInfo} />
              <div className="banner-promote-wrapper">
                <img className="auto-omne-banner" alt="onme-left-side" />
              </div>
            </div>
          </div>
        </div>
      )}
      <UseHookError err={onErrors} />
    </>
  );
});
