export const dateFormat = 'DD/MM/YYYY';

export const IMAGE_LIMIT_SIZE = 1024 * 1024 * 10; //10Mb
export const VIDEO_LIMIT_SIZE = 1024 * 1024 * 10;
export const FILE_LIMIT_SIZE = 1024 * 1024 * 10;

export const MAX_CURRENCY = 999999999999;

export const UPLOAD_ALLOW_TYPES = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'svg',
  'mp4',
  'pdf',
  'doc',
  'docx',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'webp',
];
export const UPLOAD_ALLOW_TYPES_OF_IMAGE = ['jpeg', 'jpg', 'png'];

export const UPLOAD_ALLOW_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
export const UPLOAD_ALLOW_VIDEO_TYPES = ['video/mp4'];
export const UPLOAD_ALLOW_FILE_TYPES = ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];
export const UPLOAD_ALLOW_FILE_TYPES_PDF = ['pdf'];
export const UPLOAD_ALLOW_FILE_IMPORT_TYPES = ['xlsx', 'xls'];
export const UPLOAD_ALLOW_FILE_IMAGE = ['pdf', 'jpg', 'png', 'jpeg'];

export const TAKE = 10;
export const TakeCustom = 100;
export const PAGE = 1;
export const yearThai = 543;

export const VALUE_NUMBER_INSURANCE = 'ช00008/2546';

export const EMAIL_DOMAIN = [
  { id: 1, domain: '@scb.co.th' },
  { id: 2, domain: '@fwd.com' },
];

export const mappingStepNumber = [
  {
    path: '/applications/',
    number: null,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
  {
    path: '/personal-info/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  { path: '/address/', number: 1, reCalculatePremium: false, isLoadCustomerInfo: false, isloadApplicationInfo: true },
  { path: '/contact/', number: 1, reCalculatePremium: false, isLoadCustomerInfo: true, isloadApplicationInfo: true },

  {
    path: '/loan-detail/',
    number: 2,
    reCalculatePremium: true,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
  { path: '/pdpa/', number: 3, reCalculatePremium: false, isLoadCustomerInfo: false, isloadApplicationInfo: true },
  {
    path: '/additional-info/',
    number: 4,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/insurance-info/',
    number: 5,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/beneficairy-detail/',
    number: 5,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },

  {
    path: '/health-question/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
  {
    path: '/revenue-tax/',
    number: 7,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
  { path: '/fatca/', number: 7, reCalculatePremium: false, isLoadCustomerInfo: false, isloadApplicationInfo: true },
  { path: '/crs/', number: 7, reCalculatePremium: false, isLoadCustomerInfo: false, isloadApplicationInfo: true },
  {
    path: '/clarification/',
    number: 7,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
  { path: '/consent/', number: 8, reCalculatePremium: false, isLoadCustomerInfo: true, isloadApplicationInfo: true },
  {
    path: '/upload-document/',
    number: 9,
    reCalculatePremium: true,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
  { path: '/summary/', number: null, reCalculatePremium: true, isLoadCustomerInfo: true, isloadApplicationInfo: true },
];

export const mappingAutoStepNumber = [
  {
    path: '/auto-personal-info/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-address/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-contact/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },

  {
    path: '/auto-loan-detail/',
    number: 2,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-insurance-info/',
    number: 3,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  { path: '/auto-pdpa/', number: 4, reCalculatePremium: false, isLoadCustomerInfo: true, isloadApplicationInfo: true },
  {
    path: '/auto-additional-info/',
    number: 5,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-beneficairy-detail/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-revenue-tax/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-fatca/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  { path: '/auto-crs/', number: 6, reCalculatePremium: false, isLoadCustomerInfo: true, isloadApplicationInfo: true },
  {
    path: '/auto-clarification/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-consent/',
    number: 7,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-upload-document/',
    number: 8,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-summary/',
    number: 9,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
];

export const mappingAutoStepNumberPA = [
  {
    path: '/auto-personal-info/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-address/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-contact/',
    number: 1,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },

  {
    path: '/auto-loan-detail/',
    number: 2,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-insurance-info/',
    number: 3,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  { path: '/auto-pdpa/', number: 4, reCalculatePremium: false, isLoadCustomerInfo: true, isloadApplicationInfo: true },
  {
    path: '/auto-additional-info/',
    number: 5,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-beneficairy-detail/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-revenue-tax/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-fatca/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  { path: '/auto-crs/', number: 6, reCalculatePremium: false, isLoadCustomerInfo: true, isloadApplicationInfo: true },
  {
    path: '/auto-clarification/',
    number: 6,
    reCalculatePremium: false,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-upload-document/',
    number: 7,
    reCalculatePremium: true,
    isLoadCustomerInfo: true,
    isloadApplicationInfo: true,
  },
  {
    path: '/auto-summary/',
    number: 8,
    reCalculatePremium: false,
    isLoadCustomerInfo: false,
    isloadApplicationInfo: true,
  },
];
