import { useMutation, UseMutationOptions } from 'react-query';
import { url_bff_api } from '../endpoints';
import { APIErrorResponse, RequestChannel } from '../types';
import requestApi from 'helpers/requestApi';

export type useCheckBorrowerType = UseMutationOptions<any, APIErrorResponse, RequestChannel<any>>;

export default function useCheckBorrowerType(options?: useCheckBorrowerType) {
  return useMutation<any, APIErrorResponse, RequestChannel<any>>(
    (params) =>
      requestApi.post(params.channel, url_bff_api.checkBorrowerType, params.data).then((res: any) => res.data),
    options,
  );
}
