import { List, Modal, ModalProps } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import styles from './index.module.scss';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import LASER_CODE from 'assets/images/icons/dopa_laser_code.svg';
import { ReactComponent as CHECKED } from 'assets/images/icons/Checked.svg';
import BUTTON_CLOSE from 'assets/images/icons/button-close.svg';

interface Props extends ModalProps {
  closable?: boolean;
  onCancel?: () => void;
  visible?: boolean;
}

const LaserCodeGuide: FC<Props> = memo(({ closable, onCancel, visible, children, ...props }) => {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState<boolean>(visible || false);

  useEffect(() => {
    setVisible(visible || false);
  }, [visible]);

  const data = [
    {
      title: '',
      detail: t('label:dopa_laser_code_guid_1'),
    },
    {
      title: '',
      detail: t('label:ไม่ต้องเว้นวรรค'),
    },
  ];

  return (
    <>
      <Modal
        visible={isVisible}
        closable={false}
        footer={false}
        centered
        className={cls(props.className, styles.wrapModal)}
        onCancel={() => {
          setVisible((prev) => !prev);
          onCancel?.();
        }}
        bodyStyle={{ padding: 20 }}
        {...props}
      >
        <div>
          <div
            onClick={() => {
              setVisible((prev) => !prev);
              onCancel?.();
            }}
            style={{ position: 'absolute', top: -44, right: 0 }}
          >
            <img src={BUTTON_CLOSE} alt="" />
          </div>

          <div className="tw-text-xl tw-font-scb-medium tw-mb-2 tw-text-primary tw-text-center">
            {t('label:dopa_laser_code_guid_title')}
          </div>
          <div className="tw-text-16px tw-mb-6 tw-text-center tw-font-scb-regular">
            {t('label:dopa_laser_code_guid_des')}
          </div>
          <div className="tw-text-13px tw-mb-6 tw-text-center tw-font-scb-regular">
            <img src={LASER_CODE} alt="IDCARD" />
          </div>
          <div className="tw-text-16px tw-font-scb-regular">
            <List
              itemLayout="horizontal"
              dataSource={data}
              split={false}
              renderItem={(item) => (
                <List.Item className="tw-p-0">
                  <List.Item.Meta
                    avatar={
                      <div style={{ marginRight: '11px' }}>
                        <CHECKED />
                      </div>
                    }
                    description={<div style={{ color: '#183028', fontSize: '16px' }}>{item.detail}</div>}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Modal>
    </>
  );
});

export default LaserCodeGuide;
