import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import requestApi from 'helpers/requestApi';
import { url_bff_api } from 'services/endpoints';
import { APIErrorResponse, RetrieveMyInformationResponse } from 'services/types';
import { getRequestChannelByOrigin, getUserSession } from 'helpers/common';

export const queryMeKey = 'retrieve_my_information';

export type RetrieveMyInformationOptions = UseQueryOptions<RetrieveMyInformationResponse>;

export function useMeQuery(options?: RetrieveMyInformationOptions) {
  return useQuery<RetrieveMyInformationResponse>(
    queryMeKey,
    async () => {
      const stampsession = getUserSession();
      if (stampsession) {
        const res = await requestApi.get(getRequestChannelByOrigin(), url_bff_api.retrieveMyInformation);
        return res.data;
      }
    },
    options,
  );
}

export function useMeLazyQuery(options?: RetrieveMyInformationOptions) {
  return useMutation<RetrieveMyInformationResponse>(
    queryMeKey,
    async () => {
      const res = await requestApi.get(getRequestChannelByOrigin(), url_bff_api.retrieveMyInformation);
      return res.data;
    },
    options,
  );
}

export default function useMeQueryMutation(options?: RetrieveMyInformationOptions) {
  return useMutation<any, APIErrorResponse, any>(async () => {
    const res = await requestApi.get(getRequestChannelByOrigin(), url_bff_api.retrieveMyInformation);
    return res.data;
  }, options);
}
