import { useMutation, UseMutationOptions } from 'react-query';
import { url_bff_api } from '../endpoints';
import { APIErrorResponse, RequestChannel, SaveAddressInput } from '../types';
import requestApi from 'helpers/requestApi';

export type DOPAMutationOptions = UseMutationOptions<any, APIErrorResponse, RequestChannel<any>>;

export default function useDOPAMutation(options?: DOPAMutationOptions) {
  return useMutation<any, APIErrorResponse, RequestChannel<any>>((values) => {
    return ['UAT', 'SIT', 'LOCAL'].includes(process.env.REACT_APP_ENDPOINT || '') && values?.data?.testErrorCase
      ? requestApi
          .patch(values.channel, url_bff_api.verifyDopa(values?.data.appId), values?.data.data, {
            'x-config-dopa': values?.data?.testErrorCase,
          })
          .then((res: any) => res.data)
      : requestApi
          .patch(values.channel, url_bff_api.verifyDopa(values?.data.appId), values?.data.data)
          .then((res: any) => res.data);
  }, options);
}
