import { FormInstance, Input, InputProps } from 'antd';
import classNames from 'classnames';
import { memo, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import React from 'react';

interface Props extends InputProps {
  formgroup?: FormInstance<Object>;
  focus?: boolean;
  dataTestId?: string;
}

export const AppInput: React.FC<Props> = memo(({ ...props }) => {
  const { formgroup, name, onBlur, maxLength = 255, focus } = props;
  const { className, dataTestId = '', ...restProps } = props;
  const ref = useRef<any>();

  useEffect(() => {
    if (focus) {
      ref.current.focus();
      ref.current.select();
    }
  }, [focus]);

  function onBlurInput(e: React.FocusEvent<HTMLInputElement>) {
    formgroup?.setFields([
      {
        name: name || '',
        value: e.target.value.trim(),
      },
    ]);

    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <Input
      role="presentation"
      width={props.width}
      onBlur={onBlurInput}
      className={classNames(props.className, styles.wrap)}
      autoComplete="off"
      maxLength={maxLength}
      size={'large'}
      ref={ref}
      data-testid={dataTestId}
      {...restProps}
    />
  );
});

export default AppInput;

AppInput.defaultProps = {};
