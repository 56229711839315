import { useMutation, UseMutationOptions } from 'react-query';
import { url_bff_api } from './../endpoints';
import { APIErrorResponse, RequestChannel } from './../types';
import requestApi from 'helpers/requestApi';

export type LoginMutationOptions = UseMutationOptions<APIErrorResponse>;

export default function useLogoutMutation(options?: LoginMutationOptions) {
  return useMutation<APIErrorResponse>(
    (values) => requestApi.post(null, url_bff_api.logout, values).then((res: any) => res.data),
    options,
  );
}
