import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import requestApi from 'helpers/requestApi';
import { url_bff_api } from 'services/endpoints';
import { APIErrorResponse, InsureProfileResponse, RequestChannelType } from 'services/types';

export const queryInsureInfoKey = 'query-insure-info';
export const queryInsureInfoLazyKey = 'query-insure-info-lazy';

export type InsureInformationOptions = UseQueryOptions<AnnotationJobQueryFn, APIErrorResponse, InsureProfileResponse>;

export interface InsureInfoQueryParams {
  data: {
    appId: string;
    sessionId?: string;
  };
  channel?: RequestChannelType;
}
export type AnnotationJobQueryFn = () => InsureProfileResponse;

interface UrlParam {
  data: {
    insureId: string;
    sessionId?: string;
  };
  channel?: RequestChannelType;
}

export function useInsureInfo(params: UrlParam, options?: InsureInformationOptions) {
  return useQuery<AnnotationJobQueryFn, APIErrorResponse, InsureProfileResponse>(
    [queryInsureInfoKey, params],
    () =>
      params.data.sessionId
        ? requestApi
            .get(params.channel, url_bff_api.insureProfile(params.data.insureId), undefined, {
              'session-id': params.data.sessionId,
            })
            .then((res: any) => res.data)
        : requestApi.get(params.channel, url_bff_api.insureProfile(params.data.insureId)).then((res: any) => res.data),
    options,
  );
}

export type InsureInformationLazyOptions = UseMutationOptions<
  InsureProfileResponse,
  APIErrorResponse,
  InsureInfoQueryParams
>;

export function useInsureInfoLazyQuery(options?: InsureInformationLazyOptions) {
  return useMutation<InsureProfileResponse, APIErrorResponse, InsureInfoQueryParams>(
    queryInsureInfoLazyKey,
    (params) => {
      return params.data.sessionId
        ? requestApi
            .get(params.channel, url_bff_api.insureProfile(params.data.appId), undefined, {
              'session-id': params.data.sessionId,
            })
            .then((res: any) => res.data)
        : requestApi.get(params.channel, url_bff_api.insureProfile(params.data.appId)).then((res: any) => res.data);
    },
    options,
  );
}
