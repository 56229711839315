import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import requestApi from 'helpers/requestApi';
import { url_bff_api } from 'services/endpoints';
import { APIErrorResponse, MasterDataResponse, RequestChannelType } from 'services/types';
export const queryMasterDataKey = 'query-master-data';
export type MasterDataOptions = UseQueryOptions<MasterDataResponse | undefined>;

interface MasterParam {
  data: { gender: string; language?: string };
  channel?: RequestChannelType;
}

interface GetMasterDataInput {
  data: {
    gender: string;
  };
  channel?: RequestChannelType;
}

export const getAllMasterData = (params: MasterParam) => {
  const returnedData: MasterDataResponse = {};
  return Promise.all([
    requestApi
      .get(params.channel, url_bff_api.nationality)
      .then((res: any) => ({ array: res.data, source: 'nationality' })),
    requestApi
      .get(params.channel, url_bff_api.occupations)
      .then((res: any) => ({ array: res.data, source: 'occupation' })),
    requestApi
      .get(params.channel, url_bff_api.maritalStatus)
      .then((res: any) => ({ array: res.data, source: 'maritalStatus' })),
    requestApi
      .get(params.channel, url_bff_api.titles(params.data.gender))
      .then((res: any) => ({ array: res.data, source: 'title' })),
    requestApi.get(params.channel, url_bff_api.province).then((res: any) => ({ array: res.data, source: 'provinces' })),
  ]).then((allData) => {
    for (const data of allData) {
      returnedData[data.source] = data.array;
    }
    return returnedData;
  });
};

export function useMasterDataQuery(params: MasterParam, options?: MasterDataOptions) {
  return useQuery<MasterDataResponse | undefined>(
    [queryMasterDataKey, params],
    () => getAllMasterData(params),
    options,
  );
}

export type MasterDataLazyOptions = UseMutationOptions<any, APIErrorResponse, GetMasterDataInput>;
export function useMasterDataLazyQuery(options?: MasterDataLazyOptions) {
  return useMutation<any, APIErrorResponse, GetMasterDataInput>((params) => getAllMasterData(params), options);
}
