import { memo, useEffect, useState } from 'react';
import { AppButton } from 'components/form-input/button';
import { useTranslation } from 'react-i18next';
import { MenuOutlined, LeftCircleFilled } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useLogOutMutation from 'services/mutations/useLogoutMutation';
import { AppRoutes } from 'helpers/app.routes';
import pkg from '../../../package.json';
import styles from './index.module.scss';
import cls from 'classnames';
import { checkNotAllowUrl } from 'hooks/useInvalidUrlAccess';
import RightOutline from 'assets/images/icons/right-outline.svg';
import ModalMortgageSelect from '../../components/create-app-popup/MortgagePopup';
import { TypeOfLoanEnum } from 'services/types';
import { useQueryParams } from 'hooks/useQueryParams';
import { useAutoCustomerInfoContext } from './customer-info-context';
import { clearAllSessionStorage, clearUserSession } from 'helpers/common';

interface Props {
  isLoginProp?: boolean;
  isMenu?: boolean;
  isHaveChild?: boolean;
}
const HeaderComponent: React.FC<Props> = memo(({ isLoginProp, isMenu, isHaveChild, children }) => {
  const { t, i18n } = useTranslation();
  const client = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const {
    actions: { setAutoDataCustomerInfo, setAutoDataApplicationInfo },
  } = useAutoCustomerInfoContext();

  const pathname = history?.location?.pathname;
  const params: any = useParams();
  const getParams = useQueryParams();

  const [isLogin, setLogin] = useState<boolean>(isLoginProp || false);
  const [isDrawer, setDrawer] = useState<boolean>(false);
  const [isShow, setShow] = useState<TypeOfLoanEnum | string>('');
  const isAddMore = getParams.get('isAddMore') || 0;
  const typeOfLoan = getParams.get('typeOfLoan') || '';

  const isMenuDisplay = pathname !== '/';

  useEffect(() => {
    if (typeOfLoan) {
      setShow(typeOfLoan);
    }
  }, [typeOfLoan]);

  let timer1: NodeJS.Timeout | null = null;
  useEffect(() => {
    return () => {
      if (timer1) clearTimeout(timer1);
    };
  }, [timer1]);

  const { mutate: logout, isLoading } = useLogOutMutation({
    onError: (err) => {},
  });

  const handleLogout = () => {
    logout();
    timer1 = setTimeout(() => {
      setAutoDataCustomerInfo(null);
      setAutoDataApplicationInfo(null);
      client.clear();
      localStorage.clear();
      clearUserSession();
      clearAllSessionStorage();
      setLogin(false);
      history.push(AppRoutes.login);
    }, 300);
  };

  const navigateToApplicationDetail = () => {
    if (location.pathname) {
      const validpath = checkNotAllowUrl(location.pathname);
      const appId = params?.appId;
      if (appId && validpath) history.push(AppRoutes.applicationsParams(appId));
    }
  };

  return (
    <div className="tw-flex md:tw-z-10 md:tw-relative">
      <header
        className={cls(
          styles.tabletHeader,
          'tw-relative tw-bg-white tw-shadow-default tw-flex tw-pl-4 tw-flex-col tw-w-full',
        )}
        style={{
          backgroundColor: 'tranparent !important',
        }}
      >
        <div
          className={cls(styles.tabletHeaderContent, 'tw-flex tw-justify-between tw-items-center tw-h-56px tw-flex-1')}
        >
          <img className={isHaveChild ? cls(styles.FWDImg, styles.haveBorder) : styles.FWDImg} alt="FWD" />
          <div className="tw-flex tw-items-center">
            {isLogin && !isMenuDisplay && (
              <AppButton
                type="primary"
                size="small"
                className="tw-text-13px tw-mr-4 btn-logout-home home-btn-homeLogout"
                onClick={handleLogout}
              >
                {t('label:logOut', 'Logout')}
              </AppButton>
            )}
            <img className={styles.SCBImg} alt="SCB" />
          </div>
        </div>

        {isHaveChild && <div className={cls(styles.childrenWraper, 'tw-mb-4')}>{children}</div>}
      </header>
    </div>
  );
});

export default HeaderComponent;
