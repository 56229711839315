import { useQuery, UseQueryOptions } from 'react-query';
import requestApi from 'helpers/requestApi';
import { url_bff_api } from 'services/endpoints';
import { APIErrorResponse, RequestChannelType } from 'services/types';

export type ExtendSessionOptions = UseQueryOptions<AnnotationJobQueryFn, APIErrorResponse, boolean>;

export type AnnotationJobQueryFn = () => boolean;

// interface UrlParam {
//   sessionId?: string;
// }
interface QueryParams {
  data: {
    sessionId?: string;
  };
  channel?: RequestChannelType;
}

export function useExtendSession(params: QueryParams, options?: ExtendSessionOptions) {
  return useQuery<AnnotationJobQueryFn, APIErrorResponse, boolean>(
    ['query-extend-session', params],
    () => requestApi.get(params.channel, url_bff_api.extendSession).then((res: any) => res.data),
    options,
  );
}
