import moment from 'moment';
import { DocumentTypeName, ProductType, RequestChannelEnum, RequestChannelType } from 'services/types';
import { dateFormat, yearThai } from './constants';
import { deviceDetect } from 'react-device-detect';
import { Buffer } from 'buffer';

export const formatDate = (date: any, format: string) => {
  //date format DD/MM/YYYY
  const formatInd = {
    DD: 0,
    MM: 1,
    YYYY: 2,
  };
  if (date?.toString().trim()) {
    const selectInd = formatInd[format];
    const dateArr_a = date.split('/');
    const dateArr_b = date.split('-');
    const dateArr = dateArr_a || dateArr_b;
    let result = null;
    if (!selectInd && selectInd !== 0) {
      result = dateArr.join('/');
    } else {
      result = dateArr[selectInd];
    }
    return result;
  } else {
    return '';
  }
};

export const formatNumber = (number: number) => {
  return number.toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
  });
};

function hasDecimal(num: number) {
  return !!(num % 1);
}

export const formatCurrency = (n: number, currency?: string) => {
  return (
    n.toFixed(hasDecimal(n) ? 2 : 0).replace(/./g, function (c: any, i: any, a: any) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
    }) +
    ' ' +
    (currency || '')
  );
};

export const getCommaSeparatedTwoDecimalsNumber = (number: any) => {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// Unit default: MB
export const kilobytesToSize = (megabytes: number | 0) => {
  const sizes = ['KB', 'MB', 'GB', 'TB'];
  const kilobytes = megabytes * 1024; // MB to KB

  if (kilobytes === 0 || Math.log(kilobytes) < 0) return '0 KB';

  const i = Math.floor(Math.log(kilobytes) / Math.log(1024));
  const result = Number((kilobytes / Math.pow(1024, i)).toFixed(1));

  return result.toLocaleString('ko-KR') + sizes[i];
};

export const getEnumKeyByEnumValue = (myEnum: any, enumValue: number | string): string => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
};

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function saveIMG(image: string) {
  const startIndex = image.indexOf('base64,') + 7;
  const b64 = image.substr(startIndex);
  const byteCharacters = atob(b64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const data = new Blob([byteArray], { type: 'image/jpeg' });
  return data;
}

export const isIOSDevice = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      window.navigator.userAgent,
    ) ||
    (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const getBrowser = () => {
  if (window.navigator.userAgent.indexOf('CriOS') !== -1) {
    return 'Chrome';
  } else {
    return 'Safari';
  }
};

export const checkVersion = () => {
  const agent = window.navigator.userAgent;
  // let start = agent.indexOf('OS ');
  // if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
  //   return {
  //     Model: (agent.indexOf('iPhone') > -1 ? 'iPhone' : 'iPad') || '',
  //     Version: window.Number(agent.substr(start + 3, 3).replace('_', '.')) || '',
  //   };
  // }
  // if (agent.indexOf('Android') > -1) {
  //   start = agent.indexOf('Android ');
  //   return {
  //     Model: agent.substr(start).split(';')[1].split(')')[0] || '',
  //     Version: agent.substr(start).split(';')[0].replace('Android ', '') || '',
  //   };
  // }
  return agent;
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any)?.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const getDeviceBrowserInfo = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any)?.opera || 'unknown';
  try {
    return deviceDetect(userAgent) || {};
  } catch (error) {
    return {};
  }
};

export const downloadBase64File = (contentType: string, base64Data: string, fileName: string) => {
  const popup = document.createElement('a');
  const blob = b64toBlob(base64Data, getBrowser() === 'Safari' ? 'application/octet-stream' : 'application/pdf');
  const url = window.URL.createObjectURL(blob);
  popup.href = url;
  popup.target = '_blank';
  popup.download = fileName?.endsWith('.pdf') ? decodeURIComponent(fileName) : `${decodeURIComponent(fileName)}.pdf`;
  document.body.appendChild(popup);
  popup.click();
};

export const adjustFileName = (t: any, item: any) => {
  const prefix = item?.fileName;
  if (!prefix) return prefix;

  const p_type: string = prefix.split('_')[0];
  const p_id: string = prefix.split('_')[1];
  switch (item?.documentType) {
    case DocumentTypeName.SALES_ILLUSTRATION:
      return p_type === ProductType.HEALTH
        ? `${t('label:si_health_name')}-${p_id}.pdf`
        : `${t('label:si_cl_name')}-${p_id}.pdf`;
    case DocumentTypeName.APPLICATION_FORM:
      return p_type === ProductType.HEALTH
        ? `${t('label:appform_health_name')}-${p_id}.pdf`
        : `${t('label:appform_cl_name')}-${p_id}.pdf`;
  }
};

export const getValueIfDefined = (value: any, def: any) => {
  if (value) return value;

  return def;
};

export const isInvalidDate = (date: string, month: string, year: string) => {
  const d = new Date();
  const globalYear = parseInt(year, 10) - yearThai;
  try {
    if (isNaN(parseInt(date, 10)) || isNaN(parseInt(month, 10)) || isNaN(parseInt(year, 10))) return true;
    if (parseInt(date, 10) < 1 || parseInt(date, 10) > 31) {
      if (parseInt(date, 10) === 0 && parseInt(date, 10) === 0) {
        return false;
      } else {
        return true;
      }
    } else if (globalYear === d.getFullYear() && parseInt(month, 10) > d.getMonth() + 1) {
      return false;
    } else if (
      globalYear === d.getFullYear() &&
      parseInt(month, 10) === d.getMonth() + 1 &&
      parseInt(date, 10) > d.getDate()
    ) {
      return false;
    } else {
      const newMonth = month.length === 1 ? `0${month}` : month;

      if (parseInt(date, 10) > new Date(globalYear, parseInt(newMonth, 10), 0).getDate()) {
        return true;
      } else {
        return false;
      }
    }
  } catch {
    return true;
  }
};

export const checkInvalidYear = (year: string) => {
  try {
    const globalYear = parseInt(year, 10) - yearThai;

    if (year[0] !== '2' || year[1] !== '5') return true;

    if (year.length > new Date().getFullYear().toString().length || globalYear === 0) {
      return true;
    } else {
      return false;
    }
  } catch {
    return true;
  }
};

export const checkInvalidPastYear = (year: string) => {
  const globalYear = parseInt(year, 10) - yearThai;
  if (globalYear > new Date().getFullYear()) {
    return true;
  } else {
    return false;
  }
};

export const checkInvalidMonth = (month: string) => {
  const value = parseInt(month, 10);
  if (value > 12) {
    return true;
  } else return false;
};

export const calcDiffDay = (birthday: any, today: any) => {
  const dateValue = birthday.getDate() ? birthday.getDate() : 0;
  const monthValue = birthday.getMonth() ? birthday.getMonth() : 0;
  let day = today.getDate() - dateValue;
  let month = today.getMonth() - monthValue;
  let year = today.getFullYear() - birthday.getFullYear();

  if (day < 0) {
    day += 30;
    month--;
  }

  if (month < 0) {
    month += 12;
    year--;
  }

  return {
    year: year,
    month: month,
    day: day,
  };
};

export const calcNearestAge = (dobMonth: any, dobDay: any, dobYear: any) => {
  //input month, day, year
  const dateValue = dobDay > 0 ? dobDay : 1;
  const monthValue = dobMonth > 0 ? dobMonth : 1;
  const str_birthday = `${monthValue}/${dateValue}/${parseInt(dobYear, 10) - 543}`;
  const birthday = new Date(str_birthday);
  const today = new Date();
  const diff = calcDiffDay(birthday, today);

  if (diff.month > 6 || (diff.month == 6 && diff.day > 0)) {
    diff.year++;
  }
  return diff.year;
};

export const checkInvalidGlobalAge = (globalAge: any, insuredAge: number, product?: any) => {
  return insuredAge < globalAge.min || insuredAge > globalAge.max;
};

export const getUserSession = (): string | null => {
  return window.sessionStorage.getItem('sessionId') || sessionStorage.getItem('sessionId');
};

export const getRequestChannelByOrigin = (): RequestChannelType | null | undefined => {
  const origin =
    window.location.origin === process.env.REACT_APP_API_PARTNER_URL ? RequestChannelEnum.PARTNER : undefined;
  return origin;
};

export const setDataSession = (key: string, value: any): boolean => {
  if (!key || !value) return false;
  window.sessionStorage.setItem(key, value);
  sessionStorage.setItem(key, value);
  return true;
};

export const getDataSession = (key: string): any => {
  return window.sessionStorage.getItem(key) || sessionStorage.getItem(key);
};

export const removeDataSession = (key: string): boolean => {
  window.sessionStorage.removeItem(key);
  sessionStorage.removeItem(key);
  return true;
};

export const setUserSession = (sessionId: string): boolean => {
  if (!sessionId) return false;
  window.sessionStorage.setItem('sessionId', sessionId);
  sessionStorage.setItem('sessionId', sessionId);
  return true;
};

export const generateAutoSession = () => {
  const cryptoWindow = window.crypto;
  const array = new BigUint64Array(8);
  const valArray: Array<any> = cryptoWindow?.getRandomValues(array) ? [...cryptoWindow?.getRandomValues(array)] : [];
  const crId = '_' + valArray.map((m) => m.toString(16)).join('');
  setUserSession(crId);
  return crId;
};

export const clearUserSession = (): boolean => {
  window.sessionStorage.removeItem('sessionId');
  sessionStorage.removeItem('sessionId');
  return true;
};

export const clearAllSessionStorage = (): boolean => {
  window.sessionStorage.clear();
  sessionStorage.clear();
  return true;
};

const aesDecryption = async (encryptedData: any, iv: any, authTag: any, key: any, salt: any) => {
  const algorithm = {
    name: 'AES-GCM',
    iv: iv,
    tagLength: 128,
  };

  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(key),
    { name: 'PBKDF2' },
    false,
    ['deriveKey'],
  );
  const cryptoKey = await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: new Uint8Array(salt),
      iterations: 2145,
      hash: 'SHA-512',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['decrypt'],
  );
  const encryptedArray = encryptedData;
  const authTagArray = authTag;
  const combinedData = new Uint8Array(encryptedArray.length + authTagArray.length);
  combinedData.set(encryptedArray);
  combinedData.set(authTagArray, encryptedArray.length);
  const decrypted = await window.crypto.subtle.decrypt(algorithm, cryptoKey, combinedData);
  return new TextDecoder().decode(decrypted);
};

export const decryptResponseData = async (encrypted: any): Promise<any> => {
  try {
    const secretKey = process.env.REACT_APP_CODE;
    const binData = Buffer.from(encrypted.data, 'base64');
    const salt = binData.slice(0, 64);
    const iv = binData.slice(64, 80);
    const tag = binData.slice(80, 96);
    const text = binData.slice(96);
    return await aesDecryption(text, iv, tag, secretKey, salt);
  } catch (error) {
    throw new Error('decryption error.');
  }
};

export const handleFormatIdCard = (idCard: string, statusShow: string) => {
  if (idCard && statusShow === 'true') {
    const subsIdCard = idCard.substring(idCard.length - 4, idCard.length);
    const splitIdCard = idCard.split('');
    let formatX = '';
    if (idCard.length > 4 && idCard.length != 13) {
      let setXId = '';
      for (const element of idCard.substring(0, idCard.length - 4)) {
        setXId += element; //'X';
      }
      formatX = setXId;
    }
    const convertId =
      idCard.length === 13
        ? `${splitIdCard[0]}-${splitIdCard.slice(1, 5).join('')}-${splitIdCard.slice(5, 10).join('')}-${
            splitIdCard[10]
          }${splitIdCard[11]}-${splitIdCard[12]}`
        : `${formatX}${subsIdCard}`;
    return convertId;
  } else {
    return idCard;
  }
};
