// const eventTypes = ['keypress', 'mousemove', 'mousedown', 'scroll', 'touchmove', 'pointermove'];
const eventTypes: any[] = [];
export const addEventListeners = (listener: any) => {
  eventTypes.forEach((type: any) => {
    window.addEventListener(type, listener, false);
  });
};
export const removeEventListeners = (listener: any) => {
  if (listener) {
    eventTypes.forEach((type: any) => {
      window.removeEventListener(type, listener, false);
    });
  }
};
