import { Layout } from 'antd';
import { memo, ReactNode } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import HeaderComponent from '../main/header';
import { useWindowSize, DebouncedWindowSizeOptions } from '@react-hook/window-size';

const { Content, Header } = Layout;

type Props = {
  children: ReactNode;
  isMenu?: boolean;
};

const ClientLayout = memo<Props>(({ children, isMenu }) => {
  const optionsWindow: DebouncedWindowSizeOptions = {
    initialWidth: 360,
  };
  const [onlyWidth, onlyHeight] = useWindowSize(optionsWindow);
  const user = useCurrentUser();
  const checkRenderScroll = () => {
    return onlyWidth ? (onlyWidth < 768 ? false : true) : true;
  };
  const _tabletScrollViewPort = onlyHeight ? `calc(${onlyHeight}px - 98px)` : 'calc(100vh - 98px)';

  return (
    <Layout>
      <Header
        className="tw-sticky"
        style={{
          top: 0,
          zIndex: 1,
          padding: 0,
          height: 'inherit',
          backgroundColor: '#f5f3f6',
        }}
      >
        <HeaderComponent isLoginProp={Boolean(user?.isOTPPass)} isMenu={isMenu} />
      </Header>
      <Layout
        className="tw-bg-input tw-bg-white"
        style={{
          ...(checkRenderScroll() && { overflowY: 'auto' }),
          height: checkRenderScroll() ? _tabletScrollViewPort : 'inherit',
        }}
      >
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
});

export default ClientLayout;
