import { useQueryParams } from 'hooks/useQueryParams';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetailResponse, ApplicationStatusEnum, ErrorResponse, TypeOfLoanEnum } from 'services/types';
import styles from './index.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import Spinner from 'components/spinner';
import { UseHookError } from 'hooks/useHookError';
import { LocationContext } from 'hooks';
import { getValueIfDefined } from 'helpers/common';
import { mappingAutoStepNumber, mappingAutoStepNumberPA } from 'helpers/constants';
import { useAutoCustomerInfoContext } from 'layouts/auto/customer-info-context';
import PopErrorConfirm from 'components/popup/ConfirmPop';
export interface Props {
  className?: string;
  currentStep?: number;
  appIsCall?: boolean;
  customerIsCall?: boolean;
  reCalculatePremium?: boolean;
}

export interface Step {
  title: string;
  url: string;
  progress?: number;
}

export const AutoBreadcrumbComponent: React.FC<Props> = React.memo((props) => {
  const { className, children, appIsCall, customerIsCall, reCalculatePremium } = props;
  const { t } = useTranslation('customer');
  const history = useHistory();
  const { registerLocation } = useContext(LocationContext);
  const {
    state: { autoDataCustomerInfo, autoDataApplicationInfo },
  } = useAutoCustomerInfoContext();
  //GET AppId from URL
  //CASE A
  const getParams = useQueryParams();
  const location = useLocation();

  const temp_id = getParams.get('appId');
  const getAppId = getValueIfDefined(temp_id, '');
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  //CASE B
  const { appId } = useParams<{ appId: string }>();

  const [onErrors, setOnErrors] = useState<any>({});
  const [isLocalErr, setIsLocalErr] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname) {
      const stepMapping =
        autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA
          ? mappingAutoStepNumberPA
          : mappingAutoStepNumber;
      const stepItem = stepMapping.find((item) => location.pathname.includes(item.path));
      if (stepItem && stepItem.number) setCurrentStep(stepItem.number);
    }
  }, [location.pathname, autoDataCustomerInfo]);

  const validatePersonInfoSection = Boolean(
    autoDataApplicationInfo?.insureId &&
      autoDataApplicationInfo?.contactId &&
      autoDataApplicationInfo?.percentageCompletion?.initialSubmission === 100,
  );

  const [stepList, setStepList] = useState<Step[]>([]);

  useEffect(() => {
    const stepList = [
      {
        title: t('auto:autoPersonalInfo'),
        progress: 0,
        url: AppRoutes.autoPersonalInfoParams(appId),
      },
      {
        title: t('auto:autoLoanInfo'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoLoanInfoParams(appId) : '',
      },
      {
        title: t('auto:autoCustomerInsuranceInfo'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoCustomerInsuranceInfoParams(appId) : '',
      },
      {
        title: t('auto:autoPDPA'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoPDPAParams(appId) : '',
      },
      {
        title: t('auto:autoAdditionalInfo'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoAutoAdditionalInfoParam(appId) : '',
      },
      {
        title: t('auto:autoCustomerBeneficairy'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoCustomerBeneficairyParams(appId) : '',
      },
      {
        title: t('auto:autoConsent'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoConsentParams({ appId }) : '',
      },
      {
        title: t('auto:autoUploadDocument'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoUploadDocumentParam(appId) : '',
      },
      {
        title: t('auto:autoSummary'),
        progress: 0,
        url: validatePersonInfoSection ? AppRoutes.autoSummaryParams(appId) : '',
      },
    ];

    if (autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA) {
      const textConcent = t('auto:autoConsent');
      const indexConcent = stepList.map((item) => item.title).indexOf(textConcent);
      if (indexConcent > -1) {
        stepList.splice(indexConcent, 1);
      }
    }
    setStepList(stepList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDataCustomerInfo, appId, autoDataApplicationInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckStatusOfSection = (key: number) => {
    const validatePersonInfo = Boolean(autoDataApplicationInfo?.insureId && autoDataApplicationInfo?.contactId);
    const validatePdpa = Boolean(autoDataApplicationInfo?.pdpaId?.trim());
    const checkConditionRequired = (validatePdpa && validatePersonInfo) || false;
    if (key === 1) {
      if (validatePersonInfo) return true;
    }
    if (key === 2) {
      if (validatePersonInfo) return true;
    }
    if (key === 3) if (validatePersonInfo) return true;
    if (key === 4) if (validatePersonInfo) return true;
    if (key === 5) if (validatePersonInfo) return true;
    if (key === 6) if (validatePersonInfo) return true;
    if (key === 7) if (validatePersonInfo) return true;
    if (key === 8) if (validatePersonInfo) return true;
    if (key === 9) if (validatePersonInfo) return true;
    if (key === 10) if (validatePersonInfo) return true;
    return false;
  };

  const validateDataBeforeSubmit = (applicationDetails: ApplicationDetailResponse | undefined) => {
    const percentageConsent =
      autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA
        ? true
        : applicationDetails?.percentageCompletion?.consent === 100;

    return (
      applicationDetails &&
      applicationDetails.percentageCompletion?.pdpa === 100 &&
      applicationDetails.percentageCompletion.personalInfo === 100 &&
      applicationDetails.percentageCompletion.insuranceDetail === 100 &&
      applicationDetails.percentageCompletion.additionalInfo === 100 &&
      applicationDetails.percentageCompletion.insuredInfo === 100 &&
      percentageConsent &&
      applicationDetails.percentageCompletion.uploadDocument === 100 &&
      applicationDetails.percentageCompletion.loanDetail === 100
    );
  };

  // const healthQuestionCompleted =
  //   autoDataApplicationInfo?.applicationType === TypeOfLoanEnum.HomeLoan
  //     ? autoDataApplicationInfo.percentageCompletion?.healthQuestion === 100
  //     : true;

  const widthPerStep = 95 / (stepList?.length - 2);
  // const widthPerStep = 100;
  // console.log(autoDataApplicationInfo, 'autoDataApplicationInfo');
  // console.log(autoDataCustomerInfo, 'autoDataCustomerInfo');
  // console.log(currentStep, 'currentStep');

  if (currentStep == null) return <Spinner height={'calc(100vh - 100px)'} />;

  return (
    <>
      {currentStep != null && (
        <div className={`tw-min-h-full ${className ? className : ''}`}>
          <div className={`${styles.stepsWraper}`} style={{ lineHeight: '1.5715' }}>
            <div className={styles.stepList}>
              {stepList?.map((item, index) => {
                if (item?.title) {
                  const isDone = handleCheckStatusOfSection(index + 1);
                  return (
                    <div
                      key={index}
                      className={styles.stepWraper}
                      style={{
                        width: widthPerStep + '%',
                      }}
                      onClick={() => {
                        if (isDone && item?.url) {
                          if (index + 1 === stepList.length) {
                            const isValid = validateDataBeforeSubmit(autoDataApplicationInfo);
                            if (!isValid) {
                              setIsLocalErr(true);
                            } else {
                              registerLocation(item?.url || '');
                              history.push(item?.url || '');
                            }
                          } else {
                            registerLocation(item?.url || '');
                            history.push(item?.url || '');
                          }
                        }
                        /* istanbul ignore next @preserve */
                        // if (item?.url) {
                        //   if (index + 1 === stepList.length) {
                        //     const isValid = validateDataBeforeSubmit(autoDataApplicationInfo);
                        //     if (!isValid) {
                        //       setIsLocalErr(true);
                        //     } else {
                        //       PopErrorConfirm(item);
                        //     }
                        //   } else {
                        //     // if ([3, 4, 5, 6, 7, 8, 9].includes(index + 1)) {
                        //     //   checkDOPASession(menu);
                        //     // } else {
                        //     registerLocation(item?.url || '');
                        //     history.push(item?.url || '');
                        //     // }
                        //   }
                        // }
                      }}
                    >
                      <div
                        className={
                          index + 1 <= currentStep ||
                          ![ApplicationStatusEnum.DRAFT, ApplicationStatusEnum.QUOTATION].includes(
                            autoDataCustomerInfo?.status,
                          )
                            ? `${styles.stepTitle} ${styles.customStepActive}`
                            : `${styles.stepTitle}`
                        }
                      >
                        <span>{item?.title}</span>
                      </div>
                      <div className={styles.step}>
                        <div
                          className={
                            index + 1 <= currentStep ||
                            ![ApplicationStatusEnum.DRAFT, ApplicationStatusEnum.QUOTATION].includes(
                              autoDataCustomerInfo?.status,
                            )
                              ? `${styles.customStep} ${styles.customStepActive}`
                              : `${styles.customStep}`
                          }
                        >
                          <div className={styles.customInsizeStep}></div>
                        </div>
                        <div
                          className={
                            index + 1 < currentStep ||
                            ![ApplicationStatusEnum.DRAFT, ApplicationStatusEnum.QUOTATION].includes(
                              autoDataCustomerInfo?.status,
                            )
                              ? `${styles.customBar} ${styles.customStepActive}`
                              : `${styles.customBar}`
                          }
                        ></div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}

      <PopErrorConfirm
        visible={isLocalErr}
        title={t('label:please_fullfill_data')}
        onOk={() => {
          setIsLocalErr(false);
        }}
        onCancel={() => {
          setIsLocalErr(false);
        }}
        contemtClassName={styles.errLocal}
        message={
          <div className="">
            <ul className="tw-text-left">
              {autoDataApplicationInfo?.percentageCompletion?.pdpa !== 100 && <li>{t('application:pdpa_check')}</li>}
              {autoDataApplicationInfo?.percentageCompletion?.personalInfo !== 100 && (
                <li>{t('application_personalInfo')}</li>
              )}
              {autoDataApplicationInfo?.percentageCompletion?.additionalInfo !== 100 && (
                <li>{t('application:documentAddress')}</li>
              )}
              {autoDataApplicationInfo?.percentageCompletion?.insuranceDetail !== 100 && (
                <li>{t('application_insuranceDetail')}</li>
              )}
              {autoDataApplicationInfo?.percentageCompletion?.insuredInfo !== 100 && (
                <li>{t('application_insuranceInfo')}</li>
              )}
              {autoDataCustomerInfo?.applicationType === TypeOfLoanEnum.AutoPA ? (
                <div></div>
              ) : (
                autoDataApplicationInfo?.percentageCompletion?.consent !== 100 && (
                  <li>{t('application_lifeInsurance')}</li>
                )
              )}
              {autoDataApplicationInfo?.percentageCompletion?.uploadDocument !== 100 && (
                <li>{t('application:upload_document')}</li>
              )}
              {autoDataApplicationInfo?.percentageCompletion?.loanDetail !== 100 && (
                <li>{t('application:check_loan_id')}</li>
              )}
            </ul>
          </div>
        }
        showButton
        maskClosable={false}
      ></PopErrorConfirm>
      <UseHookError err={onErrors} />
    </>
  );
});
