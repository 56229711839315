import { AppRoutes } from 'helpers';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationContext } from './locationContextProvider';

const blackLists = [
  'applications',
  'pdpa',
  'personal-info',
  'address',
  'contact',
  'insurance-info',
  'additional-info',
  'beneficairy-detail',
  'health-question',
  'revenue-tax',
  'fatca',
  'crs',
  'clarification',
  'consent',
  'upload-document',
  'loan-detail',
  'consent-landing',
  'summary',
  'thank',
  'auto',
  'auto-personal-info',
  'auto-address',
  'auto-contact',
  'auto-loan-detail',
  'auto-insurance-info',
  'auto-pdpa',
  'auto-beneficairy-detail',
  'auto-fatca',
  'auto-crs',
  'auto-clarification',
  'auto-consent',
  'auto-upload-document',
  'auto-summary',
  'auto-revenue-tax',
  'auto-additional-info',
];

export const checkNotAllowUrl = (url: string) => {
  if (!url) return true;
  const abs_path = url.split('/');
  for (let index = 0; index < blackLists.length; index++) {
    if (abs_path[1] === blackLists[index]) return true;
  }

  return false;
};

export function CheckInvalidUrlAccess() {
  const location = useLocation();
  const history = useHistory();

  const { lastVisitedLocation } = React.useContext(LocationContext);

  if (lastVisitedLocation?.current === null && checkNotAllowUrl(location?.pathname)) {
    const previousx = window.sessionStorage.getItem('latestLocation');
    if (previousx == location?.pathname) {
      //console.log('refresh case');
      return;
    }
    history.replace(AppRoutes.page404);
    history.go(0);
  }
}
