import React, { memo, useEffect, useState, useContext } from 'react';
import { NetworkStatusCode, ThrowMessageEnum } from 'services/types';
import PopErrorConfirm from 'components/popup/ConfirmPop';
import { AppRoutes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useLogOutMutation from 'services/mutations/useLogoutMutation';
import { useCustomerInfoContext } from 'layouts/main/customer-info-context';
import { LocationContext } from 'hooks';
import { clearAllSessionStorage, clearUserSession } from 'helpers/common';

interface Props {
  err?: any;
  navigate?: string;
  customTitle?: string;
  customContent?: any;
  pushNavigate?: any;
}
export const UseHookError: React.FC<Props> = memo(({ err, navigate, customTitle, customContent, pushNavigate }) => {
  const { t } = useTranslation();
  const { registerLocation } = useContext(LocationContext);
  const {
    actions: { setDataCustomerInfo, setDataApplicationInfo },
  } = useCustomerInfoContext();
  const [messageError, setMessageError] = useState<{ title?: any; content: any }>({
    title: t(`label:GENERIC_ERROR`),
    content: '',
  });
  const [error, setError] = useState<any>(null);
  const isSaveProceed = err?.isSaveProceed;

  const client = useQueryClient();
  const history = useHistory();

  let timer1: NodeJS.Timeout | null = null;
  useEffect(() => {
    return () => {
      if (timer1) clearTimeout(timer1);
    };
  }, [timer1]);

  const { mutate: logout, isLoading } = useLogOutMutation({
    onError: (err) => {},
  });

  const handleLogout = () => {
    if (history?.location?.pathname == AppRoutes.login) return;

    logout();
    timer1 = setTimeout(() => {
      setDataCustomerInfo(null);
      setDataApplicationInfo(null);
      client.clear();
      localStorage.clear();
      clearUserSession();
      clearAllSessionStorage();
      history.push(AppRoutes.login);
    }, 300);
  };

  useEffect(() => {
    if (
      err?.response?.status === NetworkStatusCode.NOT_FOUND &&
      (err?.response?.data?.message == ThrowMessageEnum.INVALID_SESSION ||
        err?.response?.data?.message == ThrowMessageEnum.SESSION_EXPIRED)
    ) {
      clearUserSession();
      setMessageError({
        title: t(`label:SESSION_EXPIRED`),
        content: (
          <div>
            {t(`home:err_session_content_a`)} <br /> {t(`home:err_session_content_b`)}
          </div>
        ),
      });
    } else if (
      err?.response?.status === NetworkStatusCode.BAD_REQUEST &&
      err?.response?.data?.message == ThrowMessageEnum.OUTDATED_CLIENT_VERSION
    ) {
      clearAllSessionStorage();
      setMessageError({
        title: t(`label:GENERIC_ERROR`),
        content: (
          <div>
            {t(`label:please_update_version_a`)} <br /> {t(`label:please_reopen_browser`)}
          </div>
        ),
      });
    } else {
      const msg = ThrowMessageEnum[err?.response?.data?.message] && t(`label:${err?.response?.data?.message}`);

      const _msg = err?.response?.data?.message;

      if (_msg == ThrowMessageEnum.LICENSE_NOT_FOUND || _msg == ThrowMessageEnum.LICENSE_EXPIRED) {
        setError(_msg);
        const errmsg = {
          ...messageError,
          title: <div style={{ textAlign: 'center' }}>{t(`label:${_msg}`)}</div>,
          content: (
            <div
              style={{
                fontWeight: 400,
                fontSize: 13,
                width: '100%',
                textAlign: 'center',
                marginLeft: 0,
                marginTop: 8,
                fontFamily: 'Noto Sans Thai',
              }}
            >
              {t(`label:${_msg}_DETAIL`) + ' '}
              <span style={{ color: '#E87722', fontWeight: 'bold', fontFamily: 'Noto Sans' }}>
                {t(`label:CONTACT_DETAIL`)}
              </span>
            </div>
          ),
        };
        setMessageError(errmsg);
      } else if (_msg == ThrowMessageEnum.IMAGE_CENSOR_ERROR) {
        setError(_msg);
        const errmsg = {
          ...messageError,
          title: <div style={{ textAlign: 'center' }}>{t(`label:IMAGE_CENSOR_TITLE_ERROR`)}</div>,
          content: (
            <div
              style={{
                fontWeight: 400,
                fontSize: 13,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              {t(`label:IMAGE_CENSOR_TITLE_MESSAGE_A`)} <br /> {t(`label:IMAGE_CENSOR_TITLE_MESSAGE_B`)}
            </div>
          ),
        };
        setMessageError(errmsg);
      } else {
        if (
          ThrowMessageEnum[err?.response?.data?.message] === 'PRODUCT_NOT_AVAILABLE_FOR_SALE' ||
          ThrowMessageEnum[err?.response?.data?.message] === 'CONCURRENT_LOGIN_ERROR' ||
          ThrowMessageEnum[err?.response?.data?.message] === 'APP_ALREADY_SUBMITTED' ||
          err?.response?.status === NetworkStatusCode.INVALID_MOBILE_OTP
        ) {
          const errmsg = {
            ...messageError,
            title: t(`label:GENERIC_ERROR`),
            content: t(_msg),
          };
          setError(_msg);
          setMessageError(errmsg);
        } else if (ThrowMessageEnum[err?.response?.data?.message] === 'INVALID_REQUEST_ACCESS') {
          window.location.href = AppRoutes.page404;
        } else if (err?.response?.status === NetworkStatusCode.GATEWAY_TIMEOUT) {
          const errmsg = {
            ...messageError,
            title: t(`label:GENERIC_ERROR`),
            content: <div dangerouslySetInnerHTML={{ __html: t(`label:GATEWAY_TIMEOUT_ERROR`) }} />,
          };
          setError(_msg);
          setMessageError(errmsg);
        } else {
          const errmsg = {
            ...messageError,
            title: msg || (isSaveProceed ? t(`label:SAVE_PROCEED_ERROR`) : t(`label:GENERIC_ERROR`)),
            content: msg
              ? t(`label:PLEASE_TRY_AGAIN`)
              : isSaveProceed
              ? t(`label:PLEASE_TRY_AGAIN`)
              : (err?.response?.data?.message && t(`label:GENERIC_ERROR_DETAIL`)) || err?.message || '',
          };
          setMessageError(errmsg);
        }
      }
      if (_msg == ThrowMessageEnum.BAD_TRIES_REACHED || _msg == ThrowMessageEnum.OTP_TIME_OUT) {
        const errmsg = {
          ...messageError,
          title: t(`label:BAD_TRIES_REACHED_TITLE`),
          content: t(`label:ERR_${_msg}`),
        };

        setMessageError(errmsg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [err]);

  return (
    <>
      <PopErrorConfirm
        visible={Boolean(messageError.content)}
        title={customTitle || messageError.title || ''}
        onOk={() => {
          if (error == ThrowMessageEnum.APP_ALREADY_SUBMITTED) {
            if (history?.location?.pathname.indexOf('auto') > -1) {
              history.push(AppRoutes.pageAutoNotFound);
            } else {
              registerLocation(AppRoutes.dashboard);
              history.push(AppRoutes.dashboard);
            }
          }
          if (
            error == ThrowMessageEnum.LICENSE_NOT_FOUND ||
            error == ThrowMessageEnum.LICENSE_EXPIRED ||
            error == ThrowMessageEnum.SESSION_EXPIRED ||
            error == ThrowMessageEnum.INVALID_SESSION ||
            ThrowMessageEnum[err?.response?.data?.message] === 'CONCURRENT_LOGIN_ERROR'
          ) {
            setMessageError({ title: 'ขออภัยในความไม่สะดวก', content: '' });
            handleLogout();
            setError(null);
          } else {
            setMessageError({ title: 'ขออภัยในความไม่สะดวก', content: '' });
            timer1 = setTimeout(() => {
              if (messageError.title === 'เซสชันหมดเวลา') {
                if (history?.location?.pathname.indexOf('auto') > -1) {
                  history.push(AppRoutes.pageAutoNotFound);
                } else {
                  window.location.href = AppRoutes.login;
                }
              } else {
                if (navigate) window.location.href = navigate;
                if (pushNavigate) history.push(pushNavigate);
              }
            }, 200);
          }
        }}
        message={customContent || messageError.content}
        showButton
        maskClosable={false}
      ></PopErrorConfirm>
    </>
  );
});
