import React, { FC, memo, ReactNode, useContext, useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, Modal, ModalProps, Row } from 'antd';
import styles from './index.module.scss';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/form-input/button';
import { AppIDCard } from 'components/form-input/id-card-input';
import AppInput from 'components/form-input/text-input';
import { INPUT_TEXT, LASER_CODE, NUMBER_REGEX } from 'helpers/regex';
import INFO from 'assets/images/icons/info.svg';
import QUESTION_MARK from 'assets/images/icons/question_mark.svg';
import { checkInvalidMonth, checkInvalidYear, formatDate, getValueIfDefined, isInvalidDate } from 'helpers/common';
import useDOPAMutation from 'services/mutations/useDOPAMutation';
import { useInsureInfoLazyQuery } from 'services/queries/useInsureInfoQuery';
import DOPA_SUCCESS from 'assets/images/icons/DOPA_success.svg';
import DOPA_ERROR from 'assets/images/icons/DOPA_error.svg';
import { AppRoutes } from 'helpers';
import { useHistory } from 'react-router-dom';
import LaserCodeGuide from 'components/laser-code-guide/LaserCodeGuide';
import { LocationContext } from 'hooks';
import { ApplicationStatusEnum, ApplicationStatusEnumType } from 'services/types';

interface Props extends ModalProps {
  icon?: ReactNode;
  title?: string;
  message?: ReactNode;
  showButton?: boolean;
  button?: ReactNode;
  buttonClassName?: string;
  onCancel?: () => void;
  onOk?: () => void;
  onClickCancelBtn?: () => void;
  loading?: boolean;
  visible?: boolean;
  manualClosable?: boolean;
  footerVisible?: boolean;
  okTextProp?: string;
  contemtClassName?: string;
  appId: string;
  isDashboard: boolean;
  onCheckingDOPASuccess?: () => void;
  dataCustomer?: any;
  isForeigner?: boolean;
  navigationURL?: string | null;
  applicationStatus?: ApplicationStatusEnumType;
}

const DOPAModal: FC<Props> = memo((props) => {
  const {
    showButton,
    button,
    buttonClassName,
    icon,
    title,
    message,
    onOk,
    onCancel,
    loading,
    visible,
    children,
    manualClosable,
    footerVisible,
    onClickCancelBtn,
    okTextProp,
    contemtClassName,
    appId,
    isDashboard,
    onCheckingDOPASuccess,
    dataCustomer,
    isForeigner,
    navigationURL,
    applicationStatus,
  } = props;
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState<boolean>(getValueIfDefined(visible, false));
  const [form] = Form.useForm();
  const [dateError, setDateError] = useState<boolean>(false);
  const [monthError, setMonthError] = useState<boolean>(false);
  const [yearError, setYearError] = useState<boolean>(false);
  const [isFormEmpty, setIsFormEmpty] = useState<boolean>(true);
  const [DOPAError, setDOPAError] = useState<any>(null);
  const history = useHistory();
  const [showPictureGuide, setShowPictureGuide] = useState<boolean>(false);
  const { registerLocation } = useContext(LocationContext);
  // const [isConfirmSendDOPA, setIsConfirmSendDOPA] = useState<boolean>(false);
  const consent_scb_link = 'https://www.scb.co.th/th/personal-banking/privacy-notice.html';
  const consent_fwd_link = 'https://www.fwd.co.th/th/privacy-policy/';
  // const policyLink = 'https://www.fwd.co.th/th/privacy-policy/';
  // const bankPolicyLink = 'https://www.scb.co.th/th/personal-banking/privacy-notice.html';

  const [focusDate, setFocusDate] = useState<number>(0); // 1: date, 2: month, 3: year

  useEffect(() => {
    setVisible(visible || false);
  }, [visible]);

  const { mutate: handleDOPAMutation, isLoading: DOPALoading } = useDOPAMutation({
    onSuccess: (res) => {
      if (res?.success) {
        setDOPAError({
          icon: DOPA_SUCCESS,
          text: res?.status === 'PENDING' ? t('label:dopa_pending') : t('label:dopa_success'),
          url: navigationURL ? navigationURL : AppRoutes?.applicationsParams(appId),
        });
      } else {
        setDOPAError({
          icon: DOPA_ERROR,
          text: res?.errorMessage,
          ...(navigationURL === 'IS_RELOAD' && { url: navigationURL }),
        });
      }
    },
    onError: (err) => {
      setDOPAError({
        icon: DOPA_ERROR,
        text: t('label:dopa_error'),
        ...(navigationURL === 'IS_RELOAD' && { url: navigationURL }),
      });
    },
  });

  const onFinish = () => {
    const formData = form.getFieldsValue();
    let submitData = {
      verificationNumber: formData?.laserCode,
      passportNumber: formData?.passportNumber,
      firstName: formData?.firstName,
      middleName: formData?.middleName,
      lastName: formData?.lastName,
      idCardNumber: formData?.idCard,
      isReverifyStep: isDashboard ? 'Y' : 'N',
      status: applicationStatus,
      consentVersion: process.env.REACT_APP_CONSENT_VERSION,
    };

    if (isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')) {
      submitData = {
        ...submitData,
        ...{
          dopaConsent: 'Y',
        },
      };
    }

    if (formData?.dobDay && formData?.dobMonth && formData?.dobYear) {
      submitData = {
        ...submitData,
        ...{
          dob: formData.dobDay + '/' + formData.dobMonth + '/' + formData.dobYear,
        },
      };
    }

    handleDOPAMutation({
      data: {
        appId: appId,
        data: submitData,
        ...(['UAT', 'SIT', 'LOCAL'].includes(process.env.REACT_APP_ENDPOINT || '') &&
          formData?.testErrorCase && { testErrorCase: formData.testErrorCase }),
      },
    });
  };

  const onDateChange = (value: any) => {
    setDateError(false);
    setYearError(false);
    setMonthError(false);
    const values = form.getFieldsValue();
    if (
      values.dobDay &&
      values.dobDay.length > 0 &&
      values.dobMonth &&
      values.dobMonth.length > 0 &&
      values.dobYear &&
      values.dobYear.length == 4
    ) {
      if (
        isInvalidDate(values.dobDay, values.dobMonth, values.dobYear) ||
        checkInvalidYear(values.dobYear) ||
        checkInvalidMonth(values.dobMonth)
      ) {
        setDateError(isInvalidDate(values.dobDay, values.dobMonth, values.dobYear));
        setYearError(checkInvalidYear(values.dobYear));
        setMonthError(checkInvalidMonth(values.dobMonth));
      }
    }
  };

  const { mutate: getInsureInfo, data: dataInsure } = useInsureInfoLazyQuery({
    onSuccess: (res) => {
      form?.setFieldsValue({
        idCard: res?.idcardNo,
        firstName: res?.firstName,
        middleName: res?.middleName,
        lastName: res?.lastName,
        dobDay: formatDate(res?.birthdate, 'DD'),
        dobMonth: formatDate(res?.birthdate, 'MM'),
        dobYear: formatDate(res?.birthdate, 'YYYY'),
      });
    },
    onError: (err: any) => {},
  });

  useEffect(() => {
    if (appId && isDashboard && isVisible) {
      getInsureInfo({ data: { appId: appId } });
    } else {
      form?.setFieldsValue({
        firstName: dataCustomer?.firstName,
        middleName: dataCustomer?.middleName,
        lastName: dataCustomer?.lastName,
        dobDay: dataCustomer?.dobDay,
        dobMonth: dataCustomer?.dobMonth,
        dobYear: dataCustomer?.dobYear,
        idCard: dataCustomer?.idCard,
      });
    }
  }, [appId, getInsureInfo, dataCustomer, isDashboard, form, isVisible]);

  const onFieldsChange = () => {
    const dataForm = form.getFieldsValue();
    if (isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')) {
      if (
        !dataForm?.laserCode?.trim() ||
        !dataForm?.dobDay?.trim() ||
        !dataForm?.dobMonth?.trim() ||
        !dataForm?.dobYear?.trim() ||
        form.getFieldError('laserCode').length ||
        form.getFieldError('dobDay').length ||
        form.getFieldError('dobMonth').length ||
        form.getFieldError('dobYear').length ||
        dateError ||
        monthError ||
        yearError
      ) {
        setIsFormEmpty(true);
      } else {
        setIsFormEmpty(false);
      }
    } else {
      if (!dataForm?.passportNumber?.trim() || form.getFieldError('passportNumber').length) {
        setIsFormEmpty(true);
      } else {
        setIsFormEmpty(false);
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({
        laserCode: '',
      });
    }
  }, [isVisible, form]);
  return (
    <Modal
      visible={isVisible}
      closable={false}
      footer={false}
      centered
      className={cls(props.className, styles.wrapModalDOPA)}
      onCancel={() => {
        setVisible((prev) => !prev);
        onCancel?.();
      }}
      bodyStyle={{ padding: '20px 2px' }}
      {...props}
    >
      <Form
        id="dopaform"
        style={{ maxHeight: '80vh', overflowY: 'auto', padding: '0 10px' }}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        scrollToFirstError
        onFieldsChange={onFieldsChange}
      >
        <div className={cls(contemtClassName, '')}>
          <div style={{ marginBottom: 24 }}>
            <div className="tw-mb-2 tw-text-primary">
              <div
                style={{ fontSize: 20 }}
                className="tw-font-scb-bold"
                dangerouslySetInnerHTML={{ __html: t('label:dopa_title') }}
              ></div>
              <div
                style={{ fontSize: 16 }}
                className="tw-font-scb-regular"
                dangerouslySetInnerHTML={
                  isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')
                    ? { __html: t('label:dopa_description') }
                    : { __html: t('label:dopa_description_foreigner') }
                }
              ></div>
            </div>
            <div id="dopaverifyform" className="tw-text-lg tw-mb-4">
              {(isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')) && (
                <AppIDCard
                  form={form}
                  placeholder="0 0000 00000 00 0"
                  label={t('application:thai_id_card')}
                  name="idCard"
                  regexMsg=" "
                  isForeigner={false}
                  requiredMsg=" "
                  readOnly={true}
                  inputStyle={{
                    height: 42,
                  }}
                />
              )}

              <Form.Item
                className={cls(styles.itemInput, 'tw-mt-2')}
                label={t('label:dopa_name')}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: ' ',
                  },
                  {
                    pattern: INPUT_TEXT,
                    message: ' ',
                  },
                ]}
              >
                <AppInput readOnly placeholder={t('application:firstname')} name="firstName" formgroup={form} />
              </Form.Item>

              <Form.Item
                className={styles.itemInput}
                label={t('label:dopa_middlename')}
                name="middleName"
                rules={[
                  // {
                  //   required: true,
                  //   message: ' ',
                  // },
                  {
                    pattern: INPUT_TEXT,
                    message: ' ',
                  },
                ]}
              >
                <AppInput readOnly placeholder={t('application:middlename')} name="middleName" formgroup={form} />
              </Form.Item>

              <Form.Item
                className={styles.itemInput}
                label={t('application:lastname')}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: ' ',
                  },
                  {
                    pattern: INPUT_TEXT,
                    message: ' ',
                  },
                ]}
              >
                <AppInput readOnly placeholder={t('application:lastname')} name="lastName" formgroup={form} />
              </Form.Item>
              {(isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')) && (
                <>
                  <Form.Item
                    className={styles.itemInput}
                    label={
                      <span>
                        {t('application:dob')} <span style={{ color: '#ff4d4f' }}>*</span>
                      </span>
                    }
                    style={{ textAlign: 'center' }}
                    rules={[
                      {
                        required: true,
                        message: ' ',
                      },
                    ]}
                  >
                    <Input.Group size="large">
                      <Row gutter={16}>
                        <Col span={5}>
                          <Form.Item
                            name="dobDay"
                            rules={[
                              {
                                required: true,
                                message: ' ',
                              },
                              {
                                pattern: NUMBER_REGEX,
                                message: ' ',
                              },
                              { min: 2, message: ' ' },
                            ]}
                            className="tw-mb-2"
                          >
                            <AppInput
                              disabled={true}
                              data-testid="inputDate"
                              className={cls(styles.dobInput, { 'has-error': dateError })}
                              maxLength={2}
                              name="dobDay"
                              placeholder={t('application:short_day', 'วว')}
                              onChange={(e) => {
                                onDateChange(e);
                                if (e.target.value.length === 2) setFocusDate(2);
                              }}
                              focus={focusDate === 1 || undefined}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name="dobMonth"
                            rules={[
                              {
                                required: true,
                                message: ' ',
                              },
                              {
                                pattern: NUMBER_REGEX,
                                message: ' ',
                              },
                              { min: 2, message: ' ' },
                            ]}
                            className="tw-mb-2"
                          >
                            <AppInput
                              data-testid="inputMonth"
                              disabled={true}
                              className={cls(styles.dobInput, { 'has-error': monthError })}
                              onChange={(e) => {
                                if (e.target.value.length === 2) setFocusDate(3);
                                if (e.target.value.length === 0) setFocusDate(1);

                                onDateChange(e);
                              }}
                              maxLength={2}
                              name="dobMonth"
                              placeholder={t('application:short_month', 'ดด')}
                              focus={focusDate === 2 || undefined}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="dobYear"
                            rules={[
                              {
                                required: true,
                                message: ' ',
                              },
                              {
                                pattern: NUMBER_REGEX,
                                message: ' ',
                              },
                              { min: 4, message: ' ' },
                            ]}
                            className="tw-mb-2"
                          >
                            <AppInput
                              data-testid="inputYear"
                              disabled={true}
                              className={cls(styles.dobInput, { 'has-error': yearError })}
                              onChange={(e) => {
                                if (e.target.value.length === 0) setFocusDate(2);
                                onDateChange(e);
                              }}
                              maxLength={4}
                              name="dobYear"
                              placeholder={t('application:short_year', 'พ.ศ.')}
                              focus={focusDate === 3 || undefined}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                  {/* <div className="tw-flex tw-text-left tw-mb-4">
                    <img style={{ marginRight: '9px' }} src={INFO} alt="infor" />
                    <div
                      style={{ lineHeight: '24px', paddingTop: '23px' }}
                      className="tw-font-scb-regular tw-text-16px"
                    >
                      {t('label:dopa_dob_info')}
                    </div>
                  </div> */}
                </>
              )}

              {(isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')) && (
                <>
                  <div style={{ lineHeight: '20px' }} className="tw-font-noto-medium tw-text-16px tw-text-left">
                    {t('label:dopa_laser_code')}
                  </div>
                  <Form.Item
                    className={styles.itemInput}
                    label={t('label:dopa_laser_code_en')}
                    name="laserCode"
                    rules={[
                      {
                        required: true,
                        message: t('label:err_msg_thaiid_lasercode'),
                      },
                      {
                        pattern: LASER_CODE,
                        message: t('label:err_msg_thaiid_lasercode'),
                      },
                    ]}
                  >
                    <AppInput
                      maxLength={12}
                      placeholder={t('label:dopa_laser_code_placeholder')}
                      name="laserCode"
                      formgroup={form}
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>

                  <div className="tw-text-left">
                    <AppButton
                      style={{
                        fontSize: '13px',
                        height: 'fit-content',
                        fontWeight: 600,
                        color: '#4F2A81',
                        border: '2px #4F2A81 solid',
                        lineHeight: '16px',
                        padding: '4px 15px',
                        marginBottom: '16px',
                      }}
                      ghost
                      onClick={() => {
                        setShowPictureGuide(true);
                      }}
                      className="tw-font-noto-bold"
                    >
                      <img style={{ marginRight: '4px' }} src={QUESTION_MARK} alt="QUESTION_MARK" />

                      {t('label:dopa_laser_code_btn')}
                    </AppButton>
                  </div>

                  <Form.Item className={styles.itemCheckbox}>
                    {/* <Checkbox
                      className="tw-text-left"
                      checked={isConfirmSendDOPA}
                      onChange={(e) => setIsConfirmSendDOPA(e.target.checked)}
                    > */}
                    <div className="tw-inline">
                      <span className="tw-mb-4 tw-block">{t('application:DOPA_confirm_send_checkbox_A')}</span>

                      <span className="tw-mb-4 tw-block">
                        {t('application:DOPA_confirm_send_checkbox_B')}
                        <a
                          href={consent_scb_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="tw-underline tw-font-scb-medium"
                        >
                          {t('application:DOPA_link')}
                        </a>
                      </span>

                      <span className="tw-mb-4 tw-block">{t('application:DOPA_confirm_send_checkbox_C')}</span>
                      <span>{t('application:DOPA_confirm_send_checkbox_D')}</span>
                    </div>
                    <a
                      href={consent_fwd_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-underline tw-font-scb-medium"
                    >
                      {t('application:PDPA_info_link')}
                    </a>
                    {/* </Checkbox> */}

                    {/* <div className="tw-inline">
                      <span>{t('application:DOPA_confirm_send_ph_1')}</span>
                      <a
                        href={policyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tw-underline tw-font-scb-medium"
                      >
                        {t('application:DOPA_confirm_send_hear')}
                      </a>
                      <span>{t('application:DOPA_confirm_send_ph_2')}</span>
                      <a
                        href={bankPolicyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tw-underline tw-font-scb-medium"
                      >
                        {t('application:DOPA_confirm_send_hear')}
                      </a>
                      <span className="tw-mb-4">{t('application:DOPA_confirm_send_ph_3')}</span>
                    </div> */}
                  </Form.Item>
                </>
              )}

              {(isForeigner === true || (dataInsure && !dataInsure?.nationality?.toUpperCase().includes('TH'))) && (
                <AppIDCard
                  form={form}
                  placeholder="AA1234567"
                  label={t('application:passport_id')}
                  name="passportNumber"
                  regexMsg=" "
                  isForeigner={true}
                  requiredMsg=" "
                  inputStyle={{
                    height: 42,
                  }}
                />
              )}
            </div>
          </div>
          {showButton && (
            <>
              {['UAT', 'SIT', 'LOCAL'].includes(process.env.REACT_APP_ENDPOINT || '') && (
                <>
                  <Form.Item
                    className={styles.itemInput}
                    label={'Input Error Case'}
                    name="testErrorCase"
                    rules={[
                      {
                        pattern: INPUT_TEXT,
                        message: ' ',
                      },
                    ]}
                  >
                    <AppInput placeholder={'Error Case'} name="testErrorCase" formgroup={form} />
                  </Form.Item>
                </>
              )}

              <div className="tw-mx-auto tw-w-full">
                <AppButton
                  loading={DOPALoading}
                  htmlType="submit"
                  type="primary"
                  className="tw-w-full tw-mb-3"
                  size="large"
                  disabled={
                    isFormEmpty
                    // ||
                    // (
                    //   !isConfirmSendDOPA &&
                    //   (isForeigner === false || dataInsure?.nationality?.toUpperCase().includes('TH')))
                  }
                >
                  {t('label:confirm_otp_btn')}
                </AppButton>
              </div>
              <div className="tw-mx-auto tw-w-full">
                <AppButton
                  onClick={() => {
                    setVisible((prev) => !prev);
                    onCancel?.();
                  }}
                  type="primary"
                  className="tw-w-full tw-mb-3"
                  size="large"
                  ghost
                  style={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#4F2A81',
                    border: '2px #4F2A81 solid',
                    lineHeight: '16px',
                    padding: '4px 11px',
                  }}
                >
                  {t('dashboard:cancel')}
                </AppButton>
              </div>
            </>
          )}
        </div>

        <Modal
          visible={DOPAError}
          closable={false}
          footer={false}
          centered
          className={cls(props.className, '')}
          bodyStyle={{ padding: '58px 25px 16px 25px' }}
          width={326}
        >
          <div className="tw-flex tw-items-center tw-w-full tw-flex-col">
            <img className="tw-mb-8" src={DOPAError?.icon} alt="success" />
            <div className="tw-mb-8 tw-text-20px tw-font-scb-medium tw-text-primary tw-text-center">
              {DOPAError?.text}
            </div>
            {DOPAError?.content && (
              <div style={{ fontSize: '16px', lineHeight: '24px' }} className="tw-mb-8 tw-font-scb-regular">
                {DOPAError?.content}
              </div>
            )}
          </div>
          <div className="tw-mx-auto tw-w-250px">
            <AppButton
              type="primary"
              className="tw-w-full tw-mb-3"
              size="large"
              onClick={() => {
                setDOPAError(null);
                if (DOPAError?.url && isDashboard) {
                  if (DOPAError?.url === 'IS_RELOAD') {
                    setDOPAError(null);
                    onCheckingDOPASuccess?.();
                  } else if (navigationURL === 'NO_NAVIGATE') {
                    setDOPAError(null);
                    onCheckingDOPASuccess?.();
                  } else {
                    registerLocation(DOPAError?.url);
                    history.push(DOPAError?.url);
                  }
                } else if (DOPAError?.url && !isDashboard) {
                  onCheckingDOPASuccess?.();
                }
              }}
            >
              {t('label:pdpa_close')}
            </AppButton>
          </div>
        </Modal>

        <LaserCodeGuide visible={showPictureGuide} onCancel={() => setShowPictureGuide(false)} closable={true} />
      </Form>
    </Modal>
  );
});

export default DOPAModal;
