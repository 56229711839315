import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { url_bff_api } from '../endpoints';
import { APIErrorResponse, UserSettings } from '../types';
import requestApi from 'helpers/requestApi';
import { getRequestChannelByOrigin, getUserSession } from 'helpers/common';

export const query_settings_key = 'query_setting_key';

export type options = UseQueryOptions<UserSettings>;

export function useQueryUserSetting(options?: options) {
  return useQuery<any>(
    query_settings_key,
    async (values) => {
      const stampsession = getUserSession();
      if (stampsession) {
        const res = await requestApi.get(getRequestChannelByOrigin(), url_bff_api.getUserSetting, values);
        return res.data;
      }
    },
    options,
  );
}

export function useLazyGetUserSetting(options?: options) {
  return useMutation<any, APIErrorResponse, any>(
    (values) =>
      requestApi.get(getRequestChannelByOrigin(), url_bff_api.getUserSetting, values).then((res: any) => res.data),
    options,
  );
}
